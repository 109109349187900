import { nextTick } from 'vue';
import { createRouter, createWebHistory } from "vue-router";
import ItemList from "@/views/Collection/ItemList";
import ItemDetail from "@/views/Collection/ItemDetail";
import MintingPage from "@/views/Collection/MintingPage";
import UserDashboard from '@/views/Account/UserDashboard';
import Trailer from '@/views/TrailerPage';
import Landing from '@/views/LandingAlliance/LandingPage';
import Alliance from '@/views/Alliance/AlliancePage';
import TheFirstAlliance from '@/views/TheFirstAlliance/TheFirstAlliancePage';
import MasterLink from '@/views/MasterLink/MasterLink';
import CollectionStore from '@/views/Collection/CollectionStorePage';

const normalRoutes = [
  {
    path: "/collection/:chain/:collection",
    name: "Collection",
    component: ItemList,
  },
  {
    path: "/collection/:chain/:collection/mint",
    name: "CollectionMinting",
    component: MintingPage,
  },
  {
    path: "/collection/:chain/:collection/:id",
    name: "CollectionDetail",
    component: ItemDetail,
  },
  {
    path: "/user",
    name: "UserDashboard",
    component: UserDashboard,
  },
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/alliance",
    name: "Alliance",
    component: Alliance,
  },
  {
    path: "/the-first-alliance",
    name: "TheFirstAlliance",
    component: TheFirstAlliance,
  },
  {
    path: "/collection-store",
    name: "CollectionStore",
    component: CollectionStore,
  },
  {
    path: "/master-link",
    name: "MasterLink",
    component: MasterLink,
    meta: {
      layout: "MasterLink",
    }
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: {
      name: "Landing",
    },
  },
];

const trailerRoutes = [
  {
    path: "/",
    name: "Trailer",
    component: Trailer,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: {
      name: "Trailer",
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: normalRoutes || trailerRoutes,
});

router.afterEach((to, from, err) => {
  if (err) {
    console.log(err);
  }
  if (to.name !== from.name) {
    nextTick(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }))
  }
})

export default router;
