import { Carousel, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import Demi from "../../assets/collection-store/demi.png";
import Crypto from "../../assets/collection-store/crypto.png";
import Bsian from "../../assets/collection-store/8sian.png";
import Capybara from "../../assets/collection-store/capybara.png";
import BsianAvatar from "../../assets/collection-store/avatar-8SIAN.png";
import DemiAvatar from "../../assets/collection-store/avatar-Demi.png";
import CryptoAvatar from "../../assets/collection-store/avatar-Crypto.png";
import CapybaraAvatar from "@/assets/capybara/avatar_128x128_black.png";
export default {
  name: "CarouselBlock",
  components: {
    Carousel,
    Slide,
    Pagination
  },

  data() {
    return {};
  },

  computed: {
    showcase() {
      return [{
        img: Crypto,
        shot: CryptoAvatar,
        title: "Alliance NFT: Hodlers High School",
        content: "Crypto Hodlers is an NFT-powered, community-driven media brand. We are building the whole universe around the collection, using comics, stories, minigames, and events. Join our community and let's build the Crypto Hodlers story together!",
        tag: "The First Alliance",
        route: {
          name: 'Collection',
          params: {
            collection: 'alliance-nft-crypto-hodlers',
            chain: 'eth'
          }
        }
      }, {
        img: Bsian,
        shot: BsianAvatar,
        title: "Alliance NFT: 8SIAN",
        content: "The 8SIAN brand aims to represent Asia in the Web3 space whilst also empowering Asians to be proud of their heritage, culture and upbringing. We strive to share our experience of NFTs and educate individuals by giving them the knowledge they need to operate in this new and exciting space.",
        tag: "The First Alliance",
        route: {
          name: 'Collection',
          params: {
            collection: 'alliance-nft-8sian',
            chain: 'eth'
          }
        }
      }, {
        img: Capybara,
        shot: CapybaraAvatar,
        title: "Capybara NFT",
        content: "For each Capybara NFT, 50% of the minting cost is transferred to the wallet address that garners all base value. The base value collected will be invested in the designated pool of Hotpot Finance to generate a high yield.",
        tag: "",
        route: {
          name: 'Collection',
          params: {
            collection: 'capybara',
            chain: 'bsc'
          }
        }
      }, {
        img: Demi,
        shot: DemiAvatar,
        title: "Alliance NFT: Demi-Human NFT",
        content: "This collection is a derivative of Demi-Human NFT. The collection, which was developed by Demiverse Studio and the InvestNFT team, aims to enhance multiple empowerments, such as NFT-insurance, NFT-forging, lottery, and CeFi offer while exploring the new form of NFT in Web3.",
        tag: "The First Alliance",
        route: {
          name: 'Collection',
          params: {
            collection: 'alliance-nft-demi-human',
            chain: 'eth'
          }
        }
      }];
    }

  },
  methods: {
    externalLink(url) {
      window.open(url, "_blank");
    },

    goRoute(data) {
      this.$router.push(data.route);
    },

    next() {
      this.$refs.carousel.next();
    },

    prev() {
      this.$refs.carousel.prev();
    }

  },

  setup() {
    return {
      // carousel settings
      settings: {
        itemsToShow: 1,
        wrapAround: true,
        snapAlign: "center"
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 768px and up
        550: {
          itemsToShow: 1
        },
        769: {
          itemsToShow: 2
        },
        // 1024 and up
        1025: {
          itemsToShow: 3
        }
      }
    };
  }

};