import bus from "@/libs/utils/bus";
export default {
  name: "ConfirmModal",

  data() {
    return {
      nftName: "",
      name: "confirm-mint"
    };
  },

  methods: {
    externalLink(url) {
      window.open(url, "_blank");
    },

    close() {
      bus.emit(`${this.name}-modal-close`);
    },

    confirm() {
      if (this.nftName.indexOf("capybara") >= 0) {
        this.externalLink("https://hotpot.finance/NFT?utm_source=investnft&utm_medium=link&utm_campaign=hyperlink");
      }

      this.$emit("confirm"); // execute mint NFT
    }

  },

  created() {
    const {
      collection
    } = this.$route.params;
    this.nftName = collection;
  }

};