import bus from "@/libs/utils/bus";
export default {
  name: "ConnectWalletModal",

  data() {
    return {
      ethereum: false
    };
  },

  methods: {
    metamask() {
      bus.emit('metamask', true);
    },

    walletConnect() {
      bus.emit('walletConnect', true);
    }

  },

  created() {
    if (window.ethereum) this.ethereum = true;
  }

};