import bus from "@/libs/utils/bus";
export default {
  name: "ItemCard",
  props: {
    product: {
      type: Object,
      default: () => ({
        name: "-",
        baseValue: 0,
        reward: 0,
        tokenId: 0,
        project: {
          image: "",
          name: ""
        }
      })
    },
    quasiStaking: {
      type: Boolean,
      default: false
    },
    isClaim: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isActionDisplay: false
    };
  },

  methods: {
    claim(e) {
      e.preventDefault();
      this.$emit('claim', this.product);
    },

    excuteAction(e, actionItem, item) {
      e.preventDefault();

      if (actionItem) {
        actionItem.excuteAction(item);
        this.moreAction(e);
      }
    }

  },

  mounted() {
    bus.on("item-card-action", id => {
      if (id !== this.product.tokenId) {
        this.isActionDisplay = false;
      }
    });
  }

};