import EmailNormal from "@/assets/icon/icon-mail-default.svg";
import EmailHover from "@/assets/icon/icon-mail-primary.svg";
export default {
  name: "CollectionHeader",
  props: {
    info: {
      type: Object,
      default: () => ({
        title: "-",
        profile: "",
        banner: "",
        desc: "-",
        items: "-",
        owners: "-",
        links: {
          web: "",
          discord: "",
          twitter: "",
          ig: ""
        }
      })
    },
    minting: {
      type: Boolean,
      default: false
    },
    collection: {
      type: String
    },
    chain: {
      type: String
    }
  },

  data() {
    return {
      isExpand: false
    };
  },

  methods: {
    externalLink(url) {
      window.open(url, "_blank");
    }

  },

  setup() {
    return {
      emailImages: {
        hover: EmailHover,
        normal: EmailNormal
      }
    };
  }

};