import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-52317ee5"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "image-wrapper"
};
const _hoisted_2 = {
  class: "text-wrapper"
};
const _hoisted_3 = {
  key: 1,
  class: "image-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_content_text = _resolveComponent("content-text");

  return _openBlock(), _createElementBlock(_Fragment, null, [$props.imagePosition === 'left' ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "image", {}, undefined, true)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [_createVNode(_component_content_text, {
    url: $props.url
  }, _createSlots({
    _: 2
  }, [this.$slots.title ? {
    name: "title",
    fn: _withCtx(() => [_renderSlot(_ctx.$slots, "title", {}, undefined, true)])
  } : undefined, this.$slots.subtitle ? {
    name: "subtitle",
    fn: _withCtx(() => [_renderSlot(_ctx.$slots, "subtitle", {}, undefined, true)])
  } : undefined, this.$slots.content ? {
    name: "content",
    fn: _withCtx(() => [_renderSlot(_ctx.$slots, "content", {}, undefined, true)])
  } : undefined, this.$slots.smallContent ? {
    name: "smallContent",
    fn: _withCtx(() => [_renderSlot(_ctx.$slots, "smallContent", {}, undefined, true)])
  } : undefined, this.$slots.button ? {
    name: "button",
    fn: _withCtx(() => [_renderSlot(_ctx.$slots, "button", {}, undefined, true)])
  } : undefined]), 1032, ["url"])]), $props.imagePosition === 'right' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_renderSlot(_ctx.$slots, "image", {}, undefined, true)])) : _createCommentVNode("", true)], 64);
}