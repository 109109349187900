import { briefAddress } from "@/libs/utils";
import bus from "@/libs/utils/bus";
import { mapState } from "vuex";
export default {
  name: "ClaimModal",
  props: {
    detail: {
      type: Object,
      default: () => ({
        image: "",
        baseValue: {
          amount: 0,
          token: "BUSD"
        },
        reward: {
          amount: 0,
          token: "BCNT"
        },
        tokenAddress: "-",
        tokenId: "-",
        name: "-",
        chain: ""
      })
    },
    isApproved: {
      type: Boolean,
      default: false
    },
    isExcuting: {
      type: Boolean,
      default: false
    },
    isPending: {
      type: Boolean,
      default: false
    },
    network: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      ethereum: false
    };
  },

  methods: {
    changeNetwork() {
      bus.emit("change-network", {
        id: this.detail.chain,
        callback: () => this.$emit("allowance")
      });
    },

    excute() {
      if (!this.isApproved) return this.$emit("approve");
      return this.$emit("claim");
    }

  },
  computed: { ...mapState({
      walletName: state => state.wallet.walletName
    })
  },

  setup() {
    return {
      briefAddress
    };
  }

};