export default {
  name: "ContentText",
  props: {
    url: {
      type: String,
      required: false
    },
    center: {
      type: Boolean,
      default: false
    }
  }
};