import useClipboard from "vue-clipboard3";
export default {
  name: "IconRounded",
  props: {
    images: {
      type: Object,
      default: () => ({
        normal: "",
        hover: ""
      })
    },
    link: {
      type: String
    },
    type: {
      type: String
    },
    tipText: {
      type: String,
      default: "Copied!"
    }
  },

  data() {
    return {
      isCopied: false
    };
  },

  methods: {
    externalLink(url) {
      window.open(url, "_blank");
    },

    onCopy() {
      this.copy(this.link);
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 1200);
    },

    excute() {
      switch (this.type) {
        case "email":
          this.onCopy();
          return;

        default:
          this.externalLink(this.link);
          break;
      }
    }

  },

  setup() {
    const {
      toClipboard
    } = useClipboard();

    const copy = async text => {
      try {
        await toClipboard(text);
      } catch (e) {
        console.error(e);
      }
    };

    return {
      copy
    };
  }

};