import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "page-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_banner_block = _resolveComponent("banner-block");

  const _component_alliance_block = _resolveComponent("alliance-block");

  const _component_features_block = _resolveComponent("features-block");

  const _component_roadmap_block = _resolveComponent("roadmap-block");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_banner_block), _createVNode(_component_alliance_block), _createVNode(_component_features_block), _createVNode(_component_roadmap_block)]);
}