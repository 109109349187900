import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-4c70b65b"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "landing-container"
};
const _hoisted_2 = {
  class: "collection-wrapper"
};
const _hoisted_3 = {
  class: "collection-contain"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_collection_store_header = _resolveComponent("collection-store-header");

  const _component_collection_card = _resolveComponent("collection-card");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_collection_store_header), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.showcase, item => {
    return _openBlock(), _createBlock(_component_collection_card, {
      product: item,
      key: `showcase-${item.name}`,
      onClick: $event => $options.goRoute(item),
      class: "image-wrapper"
    }, null, 8, ["product", "onClick"]);
  }), 128))])])]);
}