import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import AboutBlock from './AboutBlock.vue';
import AlarmBlock from './AlarmBlock.vue';
import BannerBlock from './BannerBlock.vue';
import DemiHumanBenefitsBlock from './DemiHumanBenefitsBlock.vue';
import GalleryBlock from './GalleryBlock.vue';
import InvestAndMasterLinkBenefitsBlock from './InvestAndMasterLinkBenefitsBlock.vue';
import QNABlock from './QNABlock.vue';
import TutorialBlock from './TutorialBlock.vue';
export default {
  __name: 'MasterLink',

  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", null, [_createVNode(BannerBlock), _createVNode(AboutBlock), _createVNode(GalleryBlock), _createVNode(DemiHumanBenefitsBlock), _createVNode(InvestAndMasterLinkBenefitsBlock), _createVNode(TutorialBlock), _createVNode(QNABlock), _createVNode(AlarmBlock)]);
    };
  }

};