import { createStore, createLogger } from "vuex";
import wallet from "./modules/wallet";
import token from "./modules/token";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  modules: {
    wallet,
    token,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
