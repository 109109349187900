import find from 'lodash/find';
import findIndex from "lodash/findIndex";
import { div, toAccounting } from "@/libs/utils";
import profile from "@/assets/capybara/avatar_128x128_black.png";
import banner from "@/assets/capybara/new-banner.png";
import { allianceAddressTable as allianceAddressTableTest, allianceContractMetadata as allianceContractMetadataTest, allianceInfo as allianceInfoTest, allianceList as allianceListTest } from "@/libs/const/nft-list-testnet";
import { allianceAddressTable, allianceContractMetadata, allianceInfo, allianceList } from "@/libs/const/nft-list";
const ENV = process.env.VUE_APP_ENV;
export default {
  name: "ItemList",

  data() {
    return {
      filterData: [{
        name: "Color",
        id: 1,
        options: [{
          id: 11,
          name: "yellow"
        }, {
          id: 12,
          name: "red"
        }, {
          id: 13,
          name: "black"
        }, {
          id: 14,
          name: "blue"
        }, {
          id: 15,
          name: "gray"
        }, {
          id: 16,
          name: "purple"
        }]
      }, {
        name: "Size",
        id: 2,
        options: [{
          id: 21,
          name: "small"
        }, {
          id: 22,
          name: "medium"
        }, {
          id: 23,
          name: "large"
        }]
      }, {
        name: "Type",
        id: 3,
        options: [{
          id: 31,
          name: "weak"
        }, {
          id: 32,
          name: "normal"
        }, {
          id: 33,
          name: "power"
        }]
      }],
      selectedOptions: [],
      items: {},
      list: [],
      isLoading: false,
      headerInfo: {
        title: "Capybara NFT",
        profile: profile,
        banner: banner,
        desc: "Capybara NFT, NFT immersed with intrinsic value mechanism.<br/><br/>For each Capybara NFT, 50% of the minting cost is transferred to the wallet address that garners all base value. The base value collected will be invested in the designated pool of Hotpot Finance to generate a high yield.<br/><br/>There are 1,000 Capybara NFTs in total. Except for the constantly growing intrinsic value, Capybara NFT holders are entitled to the right to mint future mysterious characters, join DAO, invest in unique liquidity pools, partake in BCNT airdrop, etc.",
        items: "-",
        owners: "-",
        links: {
          web: "https://hotpot.finance/NFT?utm_source=investnft&utm_medium=link&utm_campaign=hyperlink",
          discord: "https://discord.com/invite/Dxvm4hPRZX",
          twitter: "https://twitter.com/hotpot_finance",
          ig: "",
          email: "support@hotpot.finance"
        }
      },
      collection: "",
      chain: "",
      latestMintedId: null,
      baseValueDict: {}
    };
  },

  methods: {
    getList(address, chain) {
      this.$store.dispatch("token/getMoralisList", {
        address,
        chain
      }).then(res => {
        this.list = [...res.data.result].sort((p, c) => parseInt(c.tokenId, 10) - parseInt(p.tokenId, 10)).map(token => ({ ...token,
          image: JSON.parse(token?.metadata)?.image,
          name: JSON.parse(token?.metadata)?.name,
          baseValue: {
            token: chain === "bsc" ? "BUSD" : "USDC",
            amount: "-"
          },
          reward: {
            token: "BCNT",
            amount: "0"
          },
          bonus: {
            token: "BCNT",
            amount: 0
          },
          project: {
            image: (ENV === "SIT" ? allianceContractMetadataTest : allianceContractMetadata)[this.collection]?.image || profile,
            name: token.name
          },
          metadata: token?.metadata
        }));
        this.headerInfo.items = res.data.total;
        const checked = {};
        const rawOwners = res.data.result.map(token => token.ownerOf);
        const owners = [];
        rawOwners.forEach(address => {
          if (checked[address]) return;
          checked[address] = true;
          owners.push(address);
        });
        this.headerInfo.owners = owners.length;
        this.getBaseValues(address, this.latestMintedId);
      });
    },

    async getBaseValues(address, total) {
      const res = await this.$store.dispatch("token/getBaseValues", {
        address,
        total
      });
      const bvData = res.data.data.tokenInfos?.length ? res.data.data.tokenInfos.map(token => ({ ...token,
        tokenId: parseInt(token.tokenId, 10)
      })) : null;
      const transformedList = bvData.map((token, idx) => {
        const fIndex = findIndex(bvData, {
          tokenId: token.tokenId
        });
        return { ...token,
          tokenId: fIndex === token.tokenId ? token.tokenId : token.tokenId + (idx - fIndex)
        };
      });
      const rewardData = res.data.data.winnerEntities?.length ? res.data.data.winnerEntities.map(token => ({ ...token,
        tokenId: parseInt(token.tokenId, 10)
      })) : null;
      const rewardDict = rewardData?.map(token => ({
        [token.tokenId]: div(token.amount, 1e18).toNumber()
      }))?.reduce((p, c) => ({ ...p,
        ...c
      }), {});
      transformedList.forEach(token => {
        this.baseValueDict[token.tokenId] = {
          baseValue: div(token.baseValue, 1e18).toNumber(),
          weight: div(token.weight, 1e18).toNumber()
        };
      });
      this.list = this.list.map(token => ({ ...token,
        baseValue: {
          amount: token.ownerOf === "0xc1774E9530714834e1Cd8a7844Bd569542D655b5" ? 0 : this.baseValueDict[token.tokenId].baseValue || 0,
          token: token.baseValue.token
        },
        reward: {
          amount: rewardDict[token.tokenId],
          token: token.reward.token
        },
        bonus: {
          token: "BCNT",
          amount: 0
        }
      }));
    },

    async getCapybara() {
      try {
        const res = await this.$store.dispatch("token/getCapybara");
        this.latestMintedId = parseInt(res.data?.data?.nftInfoEntities[0].latestMintedId || 0), 10;
        Promise.resolve(this.latestMintedId);
        return this.latestMintedId;
      } catch (error) {
        Promise.reject(error);
        throw error;
      }
    },

    async getAllianceList(name, chain) {
      const list = ENV === "SIT" ? allianceListTest : allianceList;
      const {
        address,
        label,
        symbol
      } = find(list, {
        label: name
      }) || {};
      console.log(name, chain, list, address, label, symbol);
      if (!address) return [];
      let items = null;

      try {
        items = await this.$store.dispatch("token/getCollectionList", {
          symbol
        });
      } catch {
        console.log("data can not found");
      }

      const itemsList = items?.data?.data.sort((p, c) => parseInt(c.tokenID, 10) - parseInt(p.tokenID, 10)).map(token => ({ ...token,
        image: token?.metadata ? JSON.parse(token?.metadata)?.image : "https://investnft.mypinata.cloud/ipfs/QmfJFbCj74TvDkLtYZ4Kn6zMeWcuaBkvrGXL244V6TFJH3",
        name: token?.metadata ? JSON.parse(token?.metadata)?.name : token.name || (ENV === "SIT" ? allianceInfoTest : allianceInfo)[symbol].title,
        baseValue: {
          token: "USDC",
          amount: toAccounting(token?.baseValue || 0, 2)
        },
        chain: 1,
        reward: {
          token: "BCNT",
          amount: toAccounting(token.reward || 0, 2)
        },
        bonus: {
          token: "BCNT",
          amount: toAccounting(token.quasiStakingReward || 0, 2)
        },
        project: {
          image: (ENV === "SIT" ? allianceInfoTest : allianceInfo)[symbol]?.profile || profile,
          name: (ENV === "SIT" ? allianceInfoTest : allianceInfo)[symbol].title
        },
        symbol,
        label,
        tokenAddress: address,
        quasiStakingReward: toAccounting(token.quasiStakingReward || 0, 2)
      }));
      this.list = itemsList;
      const checked = {};
      const rawOwners = itemsList.map(token => token.ownerAddress);
      const owners = [];
      rawOwners.forEach(address => {
        if (checked[address]) return;
        checked[address] = true;
        owners.push(address);
      });
      this.headerInfo.owners = owners.length;
      this.headerInfo.items = itemsList.length;
      return itemsList;
    }

  },

  async created() {
    const {
      collection,
      chain
    } = this.$route.params;
    this.chain = chain;
    this.collection = collection;
    const address = collection === "capybara" ? "0x7dBBf2C8bB713418933d4BdCa7dDC904EC1B956C" : (ENV === "SIT" ? allianceAddressTableTest : allianceAddressTable)[collection];

    if (this.collection === 'capybara') {
      await this.getCapybara();
      this.getList(address, chain);
    } else {
      this.getAllianceList(this.collection, ENV === 'SIT' ? 'goerli' : 'eth');
    }
  },

  mounted() {
    const {
      collection,
      chain
    } = this.$route.params;
    this.chain = chain;
    const list = ENV === "SIT" ? allianceListTest : allianceList;
    const {
      symbol
    } = find(list, {
      label: collection
    }) || {};
    const info = (ENV === "SIT" ? allianceInfoTest : allianceInfo)[symbol];

    if (info) {
      this.headerInfo.title = info.title;
      this.headerInfo.profile = info.profile;
      this.headerInfo.banner = info.banner;
      this.headerInfo.desc = info.desc;
      this.headerInfo.links.web = info.links.web;
      this.headerInfo.links.discord = info.links.discord;
      this.headerInfo.links.twitter = info.links.twitter;
      this.headerInfo.links.email = info.links.email;
    }
  }

};