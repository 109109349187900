import Banner1 from '@/assets/landingAlliance/banner-block-1.png';
import Banner2 from '@/assets/landingAlliance/banner-block-2.png';
import Banner3 from '@/assets/landingAlliance/banner-block-3.png';
import BannerMobile1 from '@/assets/landingAlliance/banner-block-1-mobile.png';
import BannerMobile2 from '@/assets/landingAlliance/banner-block-2-mobile.png';
import BannerMobile3 from '@/assets/landingAlliance/banner-block-3-mobile.png';
export default {
  name: "BannerBlock",

  data() {
    return {
      expandEntry: 0
    };
  },

  computed: {
    entries() {
      return [{
        name: "Alliance NFT",
        route: {
          name: 'Alliance'
        },
        link: "",
        img: Banner1,
        imgMobile: BannerMobile1
      }, {
        name: "The First Alliance",
        route: {
          name: 'the-first-alliance'
        },
        link: "",
        img: Banner2,
        imgMobile: BannerMobile2
      }, {
        name: "GitBook",
        route: {
          name: ''
        },
        link: "https://investnft.gitbook.io/investnft/",
        img: Banner3,
        imgMobile: BannerMobile3
      }];
    }

  },
  methods: {
    externalLink(url) {
      window.open(url, "_blank");
    },

    goRoute(data) {
      data.link !== "" ? this.externalLink(data.link) : this.$router.push(data.route.name);
    }

  }
};