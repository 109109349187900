import { getCurrentInstance } from "vue";
import dayjs from "dayjs";
import find from "lodash/find";
import bus from "@/libs/utils/bus";
import { briefAddress, div, toAccounting } from "@/libs/utils";
import profile from "@/assets/capybara/avatar_128x128_black.png";
import banner from "@/assets/capybara/banner_black_1440x240.png";
import Blindbox from "@/assets/blindbox.gif";
import EmailNormal from "@/assets/icon/icon-mail-default.svg";
import EmailHover from "@/assets/icon/icon-mail-primary.svg";
import { allianceList as allianceListTest, allianceAddressTable as allianceAddressTableTest, allianceContractMetadata as allianceContractMetadataTest, allianceInfo as allianceInfoTest } from "@/libs/const/nft-list-testnet";
import { allianceList, allianceAddressTable, allianceContractMetadata, allianceInfo } from "@/libs/const/nft-list";
const ENV = process.env.VUE_APP_ENV;
export default {
  name: "ItemDetail",

  data() {
    return {
      detail: {},
      info: {
        title: "Capybara NFT",
        profile: profile,
        banner: banner,
        desc: "Capybara NFT, NFT immersed with intrinsic value mechanism.<br/><br/>For each Capybara NFT, 50% of the minting cost is transferred to the wallet address that garners all base value. The base value collected will be invested in the designated pool of Hotpot Finance to generate a high yield.<br/><br/>There are 1,000 Capybara NFTs in total. Except for the constantly growing intrinsic value, Capybara NFT holders are entitled to the right to mint future mysterious characters, join DAO, invest in unique liquidity pools, partake in BCNT airdrop, etc.",
        items: "-",
        owners: "-",
        links: {
          web: "https://hotpot.finance/NFT?utm_source=investnft&utm_medium=link&utm_campaign=hyperlink",
          discord: "https://discord.com/invite/Dxvm4hPRZX",
          twitter: "https://twitter.com/hotpot_finance",
          ig: "",
          email: "support@hotpot.finance"
        }
      },
      baseValue: "-",
      reward: 0,
      bonus: 0,
      isExpand: false,
      claimDate: "Any time",
      opened: dayjs().diff("2022/06/24 16:30:00+08:00") > 0,
      chain: "",
      baseValueToken: "BUSD"
    };
  },

  methods: {
    externalLink(url) {
      window.open(url, "_blank");
    },

    async getBaseValue(address, id) {
      if (this.chain !== "bsc") return;
      this.$store.dispatch("token/getBaseValue", {
        address,
        id
      }).then(res => {
        const bvData = res.data.data.tokenInfos?.length ? res.data.data.tokenInfos[0] : null;
        const rewardData = res.data.data.winnerEntities?.length ? res.data.data.winnerEntities[0] : null;
        this.baseValue = bvData ? div(bvData.baseValue, 1e18) : "-";
        this.reward = rewardData ? div(rewardData.amount, 1e18) : 0;
      });
    },

    getDetail(address, chain, id) {
      this.$store.dispatch("token/getItemDetail", {
        address,
        chain,
        id
      }).then(res => {
        this.detail = { ...res.data,
          metadata: JSON.parse(res.data.metadata)
        };
      });
    },

    async getAllianceDetail(name, chain, id) {
      const list = ENV === "SIT" ? allianceListTest : allianceList;
      const {
        address,
        label,
        symbol
      } = find(list, {
        label: name
      }) || {};
      console.log(name, chain, list, address, label, symbol);
      if (!address) return [];
      let bonus = null;

      try {
        bonus = await this.$store.dispatch("token/getCollectionList", {
          symbol
        });
      } catch {
        console.log("data can not found");
      }

      const baseValuerDict = bonus?.data.data.map(token => ({
        tokenId: token.tokenID,
        baseValue: token.baseValue
      })).reduce((p, c) => {
        p[c.tokenId] = c.baseValue;
        return p;
      }, {});
      const rewardDict = bonus?.data.data.map(token => ({
        tokenId: token.tokenID,
        reward: token.reward
      })).reduce((p, c) => {
        p[c.tokenId] = c.reward;
        return p;
      }, {});
      const bonusDict = bonus?.data.data.map(token => ({
        tokenId: token.tokenID,
        quasiStakingReward: token.quasiStakingReward
      })).reduce((p, c) => {
        p[c.tokenId] = c.quasiStakingReward;
        return p;
      }, {});
      this.$store.dispatch("token/getItemDetail", {
        address,
        chain,
        id
      }).then(res => {
        this.detail = { ...res.data,
          metadata: JSON.parse(res.data.metadata)
        };
      });
      this.baseValue = toAccounting(baseValuerDict[id], 2);
      this.reward = toAccounting(rewardDict[id], 2);
      this.bonus = toAccounting(bonusDict[id], 2);
    }

  },

  created() {
    const {
      id,
      collection,
      chain
    } = this.$route.params;
    this.chain = chain;
    this.collection = collection;
    this.baseValueToken = (ENV === "SIT" ? allianceAddressTableTest : allianceAddressTable)[collection] ? "USDC" : "BUSD";
    this.claimDate = (ENV === "SIT" ? allianceAddressTableTest : allianceAddressTable)[collection] ? "2023/04/01" : "Any time";
    const address = collection === "capybara" ? "0x7dBBf2C8bB713418933d4BdCa7dDC904EC1B956C" : (ENV === "SIT" ? allianceAddressTableTest : allianceAddressTable)[collection];

    if (this.collection === "capybara") {
      this.getDetail(address, chain, parseInt(id, 10));
      this.getBaseValue(address, parseInt(id, 10));
    } else {
      console.log(collection, ENV === "SIT" ? "goerli" : "eth", id);
      this.getAllianceDetail(collection, ENV === "SIT" ? "goerli" : "eth", id);
    }

    if ((ENV === "SIT" ? allianceInfoTest : allianceInfo)[collection]) {
      const {
        title,
        profile,
        banner,
        desc,
        links: {
          web,
          discord,
          twitter,
          email
        }
      } = (ENV === "SIT" ? allianceInfoTest : allianceInfo)[collection];
      this.info = {
        title,
        profile,
        banner,
        desc,
        links: {
          web,
          discord,
          twitter,
          email
        },
        items: "-",
        owners: "-"
      };
    } else {
      this.info = {
        title: "Capybara NFT",
        profile: (ENV === "SIT" ? allianceContractMetadataTest : allianceContractMetadata)[collection]?.image || profile,
        banner: banner,
        desc: "Capybara NFT, NFT immersed with intrinsic value mechanism.<br/><br/>For each Capybara NFT, 50% of the minting cost is transferred to the wallet address that garners all base value. The base value collected will be invested in the designated pool of Hotpot Finance to generate a high yield.<br/><br/>There are 1,000 Capybara NFTs in total. Except for the constantly growing intrinsic value, Capybara NFT holders are entitled to the right to mint future mysterious characters, join DAO, invest in unique liquidity pools, partake in BCNT airdrop, etc.",
        items: "-",
        owners: "-",
        links: {
          web: "https://hotpot.finance/NFT?utm_source=investnft&utm_medium=link&utm_campaign=hyperlink",
          discord: "https://discord.com/invite/Dxvm4hPRZX",
          twitter: "https://twitter.com/hotpot_finance",
          ig: "",
          email: "support@hotpot.finance"
        }
      };
    }
  },

  setup() {
    const {
      ctx
    } = getCurrentInstance();
    bus.on("change-network-success", id => {
      // ctx.netWorkChanged(id);
      console.log(id, ctx.chain);
    });
    return {
      briefAddress,
      Blindbox,
      chainList: {
        bsc: 56,
        ethereum: 1
      },
      emailImages: {
        hover: EmailHover,
        normal: EmailNormal
      }
    };
  }

};