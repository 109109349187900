import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-02507ce6"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "font-h3 title"
};
const _hoisted_2 = {
  key: 4,
  class: "action-button"
};
const _hoisted_3 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      center: $props.center
    })
  }, [this.$slots.title ? (_openBlock(), _createElementBlock("h3", _hoisted_1, [_renderSlot(_ctx.$slots, "title", {}, undefined, true)])) : _createCommentVNode("", true), this.$slots.subtitle ? (_openBlock(), _createElementBlock("p", {
    key: 1,
    class: _normalizeClass([{
      first: !this.$slots.title
    }, "font-h5 subtitle"])
  }, [_renderSlot(_ctx.$slots, "subtitle", {}, undefined, true)], 2)) : _createCommentVNode("", true), this.$slots.content ? (_openBlock(), _createElementBlock("p", {
    key: 2,
    class: _normalizeClass([{
      first: !this.$slots.title && !this.$slots.subtitle
    }, "font-body1 content"])
  }, [_renderSlot(_ctx.$slots, "content", {}, undefined, true)], 2)) : _createCommentVNode("", true), this.$slots.smallContent ? (_openBlock(), _createElementBlock("p", {
    key: 3,
    class: _normalizeClass([{
      first: !this.$slots.title && !this.$slots.subtitle && !this.$slots.content
    }, "font-body2 smallContent"])
  }, [_renderSlot(_ctx.$slots, "smallContent", {}, undefined, true)], 2)) : _createCommentVNode("", true), this.$slots.button ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("a", {
    href: $props.url,
    target: "_blank"
  }, [_renderSlot(_ctx.$slots, "button", {}, undefined, true)], 8, _hoisted_3)])) : _createCommentVNode("", true)], 2);
}