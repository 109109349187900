import BannerBlock from "./BannerBlock.vue";
import FeaturesBlock from "./FeaturesBlock.vue";
import AllianceBlock from "./AllianceBlock.vue";
import RoadmapBlock from "./RoadmapBlock.vue";
export default {
  components: {
    BannerBlock,
    FeaturesBlock,
    AllianceBlock,
    RoadmapBlock
  },
  name: "AlliancePage"
};