import find from 'lodash/find';
import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import bus from "@/libs/utils/bus";
import dayjs from "dayjs";
import { mapState } from "vuex";
import { briefAddress, toAccounting } from "@/libs/utils/";
import { mint, checkWhitelistAmount, nftBalanceOf } from "@/libs/methods";
import ABI from "@/abi";
import profile from "@/assets/capybara/avatar_128x128_black.png";
import Blindbox from "@/assets/blindbox.gif";
import BlindboxAlliance from "@/assets/blindbox-alliance.gif";
import EmailNormal from "@/assets/icon/icon-mail-default.svg";
import EmailHover from "@/assets/icon/icon-mail-primary.svg";
import { allianceMintingTable as allianceMintingTableTest, allianceMintingAddressTable as allianceMintingAddressTableTest, allianceWhitelist as allianceWhitelistTest, genesisAddressList as genesisAddressListTest, allianceList as allianceListTest } from "@/libs/const/nft-list-testnet";
import { allianceMintingTable, allianceMintingAddressTable, allianceWhitelist, genesisAddressList, allianceList } from "@/libs/const/nft-list";
const ENV = process.env.VUE_APP_ENV;
const ALLIANCE_CHAIN_ID = process.env.VUE_APP_ALLIANCE_CHAIN_ID;
export default {
  name: "CollectionMint",

  data() {
    return {
      ENV,
      isToggle: false,
      amount: 1,
      detail: {},
      symbol: null,
      info: {
        title: "Capybara NFT",
        fileName: "Capybara",
        profile: profile,
        price: 100,
        unit: "BUSD",
        item: "1,000",
        desc: "This is the future of all illiquid NFTs. Owners are guaranteed to retrieve at least 50% of the mint price back anytime.",
        items: "-",
        owners: "-",
        maxAmountWhitelist: null,
        links: {
          web: "https://hotpot.finance/NFT?utm_source=investnft&utm_medium=link&utm_campaign=hyperlink",
          discord: "https://discord.com/invite/Dxvm4hPRZX",
          twitter: "https://twitter.com/hotpot_finance",
          ig: "",
          email: "support@hotpot.finance"
        }
      },
      mintingDates: ["2022/06/21 15:00:00+08:00", "2022/06/22 15:00:00+08:00"],
      nowMintingDate: null,
      mintLoading: false,
      mintedAmount: 0,
      isPublicsale: false,
      checking: false,
      genesisBalance: [],
      isRightChainId: null
    };
  },

  computed: { ...mapState({
      currentAccount: state => state.wallet.walletAddress,
      walletName: state => state.wallet.walletName,
      isTransactionPending: state => state.wallet.isTransactionPending,
      chainId: state => state.wallet.chainId
    }),

    date() {
      return {
        date: dayjs(this.nowMintingDate).format("HH:mm, MMM D, YYYY"),
        time: dayjs(this.nowMintingDate).format("HH:mm")
      };
    },

    showcase() {
      const result = [];

      for (var i = 1; i <= 6; i++) {
        result.push({
          img: `https://s3.ap-northeast-2.amazonaws.com/web-pro.bincentive.com/iNFT/image/${this.info.fileName}/Sample/${i}.png`,
          name: String.fromCharCode(96 + i)
        });
      }

      return result;
    },

    isOverWhitelistLimit() {
      return this.info.maxAmountWhitelist === null ? false : this.mintedAmount + this.amount > this.info.maxAmountWhitelist;
    },

    isOpen() {
      const result = dayjs().diff(this.nowMintingDate) < 0 ? false : true;
      return result;
    },

    hasGenesis() {
      const balanceResult = this.genesisBalance.map(balance => balance > 0).reduce((p, c) => p || c, false);
      const isCHPresale = !this.isPublicsale && this.collection === "alliance-nft-crypto-hodlers";
      return isCHPresale ? true : balanceResult;
    }

  },
  watch: {
    currentAccount(account) {
      if (account) {
        this.checkMintedAmountWhiteList();
        this.checkGenesisBalance();
      }
    },

    chainId(id) {
      if (id) {
        const result = parseInt(id, 10) === parseInt(ALLIANCE_CHAIN_ID, 10);
        this.isRightChainId = result;

        if (this.currentAccount && result) {
          this.checkMintedAmountWhiteList();
          this.checkGenesisBalance();
        }

        if (result === false) alert("Please change to the right network");
      }
    }

  },
  methods: {
    externalLink(url) {
      window.open(url, "_blank");
    },

    checkDate() {
      const result = dayjs().diff(this.nowMintingDate) < 0 ? false : true;
      return result;
    },

    changeAmount(val) {
      switch (val) {
        case 1:
          this.amount = this.amount === 10 ? this.amount : parseInt(this.amount, 10) + 1;
          break;

        case -1:
          this.amount = parseInt(this.amount, 10) > 1 ? parseInt(this.amount, 10) - 1 : 1;
          break;

        default:
          break;
      }
    },

    openConfirm() {
      if (this.symbol === 'DEM') return;

      if (!this.currentAccount && this.collection !== "capybara" && !this.mintLoading) {
        return bus.emit("connect-wallet-modal-status", true);
      }

      const whitelist = (ENV === "SIT" ? allianceWhitelistTest : allianceWhitelist)[this.symbol];
      const index = whitelist ? whitelist?.map(address => address.toLowerCase()).indexOf(this.currentAccount.toLowerCase()) : -1;

      if (this.isRightChainId === false) {
        return alert("Please change to the right network");
      }

      if (!this.isPublicsale && this.isOverWhitelistLimit) {
        return alert(`Over maximum. \n Max: ${this.info.maxAmountWhitelist} \n Minted now: ${this.mintedAmount}`);
      }

      if (!this.hasGenesis) {
        return alert("You have no genesis NFT");
      }

      if (!this.isPublicsale && index === -1) {
        this.isToggle = false;
        return alert("You are not in the whitelist");
      }

      if (!this.isPublicsale ? !this.isOverWhitelistLimit && this.isOpen : this.isOpen) {
        this.isToggle = !this.isToggle;
      }
    },

    async setTxStatusInterval(tx) {
      const status = await this.getTxStatus(tx);
      console.log(tx, status);
      this.mintLoading = status === null ? true : false;

      if (status === null) {
        setTimeout(() => {
          this.setTxStatusInterval(tx);
        }, 3000);
      } else {
        this.checkMintedAmountWhiteList();
      }
    },

    genProof() {
      const whitelist = (ENV === "SIT" ? allianceWhitelistTest : allianceWhitelist)[this.symbol];
      if (whitelist === null) return null;
      const leaves = whitelist.map(address => keccak256(address));
      const tree = new MerkleTree(leaves, keccak256, {
        sort: true
      });
      const leaf = keccak256(this.currentAccount).toString("hex");
      const proof = tree.getHexProof(leaf);
      const root = tree.getHexRoot();
      console.log(proof, root);
      return proof;
    },

    async checkMintedAmountWhiteList() {
      const whitelist = (ENV === "SIT" ? allianceWhitelistTest : allianceWhitelist)[this.collection];

      if (whitelist === null) {
        this.mintedAmount = 0;
        return;
      }

      const Contract = new window.web3.eth.Contract(ABI[this.collection], (ENV === "SIT" ? allianceMintingAddressTableTest : allianceMintingAddressTable)[this.symbol]);
      const minted = await checkWhitelistAmount({
        contract: Contract,
        payload: {
          account: this.currentAccount
        }
      });
      this.mintedAmount = parseInt(minted[1], 10);
    },

    mint() {
      const Contract = new window.web3.eth.Contract(ABI[this.collection], (ENV === "SIT" ? allianceMintingAddressTableTest : allianceMintingAddressTable)[this.symbol]);
      this.mintLoading = true;
      mint({
        contract: Contract,
        payload: {
          amount: `${this.amount}`,
          account: this.currentAccount,
          value: window.web3.utils.toWei(`${toAccounting(this.info.price * this.amount, 5, true)}`, "ether"),
          ...(this.symbol === '8sian' ? {} : {
            proof: []
          })
        },
        onSuccess: res => {
          if (res) {
            this.setTxStatusInterval(res);
            this.isToggle = false;
          }
        },
        onError: err => {
          if (err) {
            this.mintLoading = false;
            alert("User reject the transaction");
            this.isToggle = false;
            console.log(err, "mint");
          }
        }
      });
    },

    async getNFTBalanceOf(nft) {
      const Contract = new window.web3.eth.Contract(ABI[nft.label], nft.address);
      let balance = null;

      try {
        balance = await nftBalanceOf({
          contract: Contract,
          payload: {
            account: this.currentAccount
          }
        });
      } catch {
        return null;
      }

      return parseInt(balance, 10);
    },

    async checkGenesisBalance() {
      if (this.isRightChainId === false) return;
      const genesisList = ENV === "SIT" ? genesisAddressListTest : genesisAddressList;
      this.checking = true;
      Promise.all(genesisList.map(nft => this.getNFTBalanceOf(nft))).then(res => {
        this.genesisBalance = res.map(balance => balance);
        this.checking = false;

        if (this.genesisBalance.filter(balance => balance !== null).length !== this.genesisBalance.length) {
          this.checkGenesisBalance();
        }
      }).catch(err => {
        console.log(err, "checkGenesisBalance");
        this.checking = false;
      });
    }

  },

  mounted() {
    bus.on("confirm-mint-modal-close", () => this.isToggle = false);
  },

  created() {
    const {
      collection,
      chain
    } = this.$route.params;
    this.collection = collection;
    this.chain = chain;
    const list = ENV === "SIT" ? allianceListTest : allianceList;
    const {
      symbol
    } = find(list, {
      label: collection
    }) || {};
    this.symbol = symbol;
    const nowStatus = dayjs().diff(this.mintingDates[this.mintingDates.length - 1]) > 0 ? 1 : 0;
    this.nowMintingDate = this.mintingDates.length > 1 ? this.mintingDates[nowStatus] : this.mintingDates[0];
    const info = (ENV === "SIT" ? allianceMintingTableTest : allianceMintingTable)[symbol];

    if (info) {
      this.info.title = info.title;
      this.info.fileName = info.fileName;
      this.info.profile = info.profile;
      this.info.desc = info.desc;
      this.info.unit = info.unit;
      this.info.price = info.price;
      this.info.item = info.item;
      this.info.links.web = info.links.web;
      this.info.links.discord = info.links.discord;
      this.info.links.twitter = info.links.twitter;
      this.info.links.email = info.links.email;
      this.info.maxAmountWhitelist = info.maxAmountWhitelist;
      this.mintingDates = info.mintingDates;
      const nowStatus = dayjs().diff(this.mintingDates[this.mintingDates.length - 1]) > 0 ? 1 : 0;
      this.nowMintingDate = this.mintingDates[this.mintingDates.length > 1 ? nowStatus : 0];
      this.isPublicsale = dayjs().diff(this.mintingDates[this.mintingDates.length - 1]) > 0;
    }

    if (this.currentAccount) {
      this.checkMintedAmountWhiteList();
      this.checkGenesisBalance();
    }

    if (this.chainId) {
      const result = parseInt(this.chainId, 10) === parseInt(ALLIANCE_CHAIN_ID, 10);
      this.isRightChainId = result;
      if (result === false) alert("Please change to the right network");
    }
  },

  setup() {
    return {
      briefAddress,
      BlindboxAlliance,
      Blindbox,
      chainList: {
        bsc: 56,
        ethereum: 1
      },
      emailImages: {
        hover: EmailHover,
        normal: EmailNormal
      }
    };
  }

};