import cryptoHodlersWhitelist from "./whitelist/alliance-nft-crypto-hodlers-testnet.js";
import demiHumanWhitelist from "./whitelist/alliance-nft-demi-human-testnet.js";

export const allianceAddressTable = {
  "DEM": "0x5d50f799aFc5b65713548d94f931B0B055B4Bf07",
  "CRHDL": "0xab26Da26666EC87962be60E24fea77E1eeBa4D9D",
  "8sian": "0xDeFB4c0498859754043cD79741b49FAeE688BcB5",
};

export const allianceMintingAddressTable = {
  "DEM": "0x58F763edEc63834ce556f09b0A44994C22517Ac8",
  "CRHDL": "0x913B41FDE460ffe10Ce9872276430c2DCDa03E9C",
  "8sian": "0x8122319Dad31Ad4b40C71561B5B8C6537b20C244",
};

export const genesisAddressList = [
  {
    label: "genesis-demi-human",
    address: "0x51C00A797142BC98bf2147DE54F16033Aca8e645",
  },
  {
    label: "genesis-crypto-hodlers",
    address: "0x04277a27A0A9FE72a1d01371A584600a93F6A2eF",
  },
  {
    label: "genesis-8sian",
    address: "0x72d2E4c14fc2B2dbf7137713460f0B846E3D4E4D",
  },
];

export const allianceList = [
  {
    label: "alliance-nft-demi-human",
    address: "0x5d50f799aFc5b65713548d94f931B0B055B4Bf07",
    symbol: "ALLIA",
  },
  {
    label: "alliance-nft-crypto-hodlers",
    address: "0xab26Da26666EC87962be60E24fea77E1eeBa4D9D",
    symbol: "ALLIB",
  },
  {
    label: "alliance-nft-8sian",
    address: "0xDeFB4c0498859754043cD79741b49FAeE688BcB5",
    symbol: "ALLIC",
  },
];

export const allianceInfo = {
  "CRHDL": {
    title: "Alliance NFT: Hodlers High School",
    profile:
      "https://s3.ap-northeast-2.amazonaws.com/web-pro.bincentive.com/iNFT/image/CryptoHodler/Logo/avatar.png",
    banner:
      "https://s3.ap-northeast-2.amazonaws.com/web-pro.bincentive.com/iNFT/image/CryptoHodler/Banner/banner.png",
    desc: `
    High School - is the new NFT collection that represents the new generation of Crypto Hodlers. The collection consists of 250 unique generated characters built from more than 350 different traits. Join them in their new journey and become a student of Famas High School. The adventures are waiting for you. Don't miss them.
    <br/><br/>
    In addition to all and awesome Alliance NFT features, owning a High School token will give you acsess to most of the Crypto Hodlers community benefits, such as rewards, inner events, and mini-games.
    `,
    links: {
      web: "https://cryptohodlers.io",
      discord: "https://discord.gg/xtnFWhbBbF",
      twitter: "https://twitter.com/Hodlers_NFT",
      email: "",
    },
  },
  "DEM": {
    title: "Alliance NFT: Demi-Human NFT",
    profile:
      "https://s3.ap-northeast-2.amazonaws.com/web-pro.bincentive.com/iNFT/image/Demi-Human/Logo/avatar.png",
    banner:
      "https://s3.ap-northeast-2.amazonaws.com/web-pro.bincentive.com/iNFT/image/Demi-Human/Banner/banner.png",
    desc: "This collection is a derivative of Demi-Human NFT. The collection, which was developed by Demiverse Studio and the InvestNFT team, aims to enhance multiple empowerments, such as NFT-insurance, NFT-forging, lottery, and CeFi offer while exploring the new form of NFT in Web3.",
    links: {
      web: "https://www.demiversestudio.com/",
      discord: "https://discord.gg/xtnFWhbBbF",
      twitter: "https://twitter.com/DemihumanNft",
      email: "genehuang@demiverse.io",
    },
  },
  "8sian": {
    title: "Alliance NFT: 8SIAN",
    profile:
      "https://s3.ap-northeast-2.amazonaws.com/web-pro.bincentive.com/iNFT/image/8SIAN/Logo/avatar.png",
    banner:
      "https://s3.ap-northeast-2.amazonaws.com/web-pro.bincentive.com/iNFT/image/8SIAN/Banner/banner.png",
    desc: `
    8SIAN's aim was to create elegant, Asian female drawings that represented the unique characteristics and traits of women across Asia. One of our core visions is to be able to combine cultures from Asia and create something authentic. Every individual NFT in our collection speaks for itself in terms of quality and each one has a unique cultural background and story to tell.
    <br/><br/>
    The 8SIAN brand aims to represent Asia in the Web3 space whilst also empowering Asians to be proud of their heritage, culture and upbringing. We strive to share our experience of NFTs and educate individuals by giving them the knowledge they need to operate in this new and exciting space. We also aim to bridge the gap between the physical and virtual worlds by expanding our community and through collaborations with key partners in various industries.
    `,
    links: {
      web: "https://8sian.io/collections",
      discord: "https://discord.gg/xtnFWhbBbF",
      twitter: "https://twitter.com/8siannft",
      email: "hello@8sian.io",
    },
  },
};

export const allianceContractMetadata = {
  "DEM": {
    name: "Alliance NFT: Demi-Human NFT",
    description:
      "Alliance NFT is a new NFT Pass launched by alliance partners. This NFT is used for accessing new utilities and communities of Alliance NFT partners.",
    image:
      "https://investnft.mypinata.cloud/ipfs/QmbEd4GtF7nPdJnuz77fDpMRZVn181hBddWKfUfi3Dr9WE",
    external_link:
      "https://www.investnft.finance/collection/eth/alliance-nft-demi-human",
    seller_fee_basis_points: 500,
    fee_recipient: "0xEc0b83e36090989A35a9b7A7316e55c404716eB5",
  },
  "CRHDL": {
    name: "Alliance NFT: Hodlers High School",
    description:
      "Crypto Hodlers is an NFT-powered, community-driven media brand. We are building the whole universe around the collection, using comics, stories, minigames, and events.\n\nJoin our community and let's build the Crypto Hodlers story together!",
    image:
      "https://investnft.mypinata.cloud/ipfs/QmQEnynE2uhfojACCp4bmA8FXh9zYkTEry9DNnmDQD1qfZ",
    external_link:
      "https://www.investnft.finance/collection/eth/alliance-nft-crypto-hodlers",
    seller_fee_basis_points: 500,
    fee_recipient: "0x9d4Fa76bDb46DD37a501B7d6fce12c47f390a0af",
  },
  "8sian": {
    name: "Alliance NFT: 8SIAN",
    description:
      "The 8SIAN brand aims to represent Asia in the Web3 space whilst also empowering Asians to be proud of their heritage, culture and upbringing. We strive to share our experience of NFTs and educate individuals by giving them the knowledge they need to operate in this new and exciting space. We also aim to bridge the gap between the physical and virtual worlds by expanding our community and through collaborations with key partners in various industries.",
    image:
      "https://investnft.mypinata.cloud/ipfs/QmevMhSX3uXZSVSPGj9Re9x3Aik4chtbrH2jLHVR7isi5i",
    external_link:
      "https://www.investnft.finance/collection/eth/alliance-nft-8sian",
    seller_fee_basis_points: 500,
    fee_recipient: "0x8abF2830664F0037C4A97852fD6CAe684bBcaE3d",
  },
};

export const allianceMintingTable = {
  "DEM": {
    title: "Alliance NFT: Demi-Human NFT",
    fileName: "Demi-Human",
    profile:
      "https://s3.ap-northeast-2.amazonaws.com/web-pro.bincentive.com/iNFT/image/Demi-Human/Logo/avatar.png",
    desc: "Alliance NFT is a new NFT Pass launched by alliance partners. This NFT is used for accessing new utilities and communities of Alliance NFT partners.",
    unit: "ETH",
    price: 0.001,
    item: "250",
    maxAmountWhitelist: 10,
    links: {
      web: "https://www.demiversestudio.com/",
      discord: "https://discord.gg/xtnFWhbBbF",
      twitter: "https://twitter.com/DemihumanNft",
      email: "genehuang@demiverse.io",
    },
    mintingDates: ["2022/10/02 00:00:00+08:00", "2022/10/03 13:00:00+08:00"],
  },
  "CRHDL": {
    title: "Alliance NFT: Hodlers High School",
    fileName: "CryptoHodler",
    profile:
      "https://s3.ap-northeast-2.amazonaws.com/web-pro.bincentive.com/iNFT/image/CryptoHodler/Logo/avatar.png",
    desc: "Alliance NFT is a new NFT Pass launched by alliance partners. This NFT is used for accessing new utilities and communities of Alliance NFT partners.",
    unit: "ETH",
    price: 0.1,
    item: "250",
    maxAmountWhitelist: 5,
    links: {
      web: "https://cryptohodlers.io",
      discord: "https://discord.gg/xtnFWhbBbF",
      twitter: "https://twitter.com/Hodlers_NFT",
      email: "",
    },
    mintingDates: ["2022/09/03 13:00:00+08:00", "2022/10/01 02:00:00+08:00"],
  },
  "8sian": {
    title: "Alliance NFT: 8SIAN",
    fileName: "8SIAN",
    profile:
      "https://s3.ap-northeast-2.amazonaws.com/web-pro.bincentive.com/iNFT/image/8SIAN/Logo/avatar.png",
    desc: "Alliance NFT is a new NFT Pass launched by alliance partners. This NFT is used for accessing new utilities and communities of Alliance NFT partners.",
    unit: "ETH",
    price: 0.1,
    item: "100",
    maxAmountWhitelist: null,
    links: {
      web: "https://8sian.io/collections",
      discord: "https://discord.gg/xtnFWhbBbF",
      twitter: "https://twitter.com/8siannft",
      email: "hello@8sian.io",
    },
    mintingDates: ["2022/09/30 08:00:00+08:00"],
  },
};

export const allianceWhitelist = {
  "DEM": demiHumanWhitelist,
  "CRHDL": cryptoHodlersWhitelist,
  "8sian": null,
};
