import bus from "@/libs/utils/bus"; //import Camber from "../../assets/collection-store/camber-small-light.svg";

export default {
  name: "CollectionCard",
  props: {
    product: {
      type: Object,
      default: () => ({
        name: "-",
        title: "-",
        content: "-",
        tag: "-",
        img: "-",
        shot: "-"
      })
    },
    actions: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isActionDisplay: false
    };
  },

  methods: {
    moreAction(e) {
      e.preventDefault();
      bus.emit("item-card-action", this.product.tokenId);
      this.isActionDisplay = !this.isActionDisplay;
    },

    excuteAction(e, actionItem, item) {
      e.preventDefault();

      if (actionItem) {
        actionItem.excuteAction(item);
        this.moreAction(e);
      }
    }

  },

  mounted() {
    bus.on("item-card-action", id => {
      if (id !== this.product.tokenId) {
        this.isActionDisplay = false;
      }
    });
  }

};