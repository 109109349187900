export default {
  name: "FeatureBadgeCard",
  props: {
    badgeUrl: {
      required: true
    },
    title: {
      type: String
    },
    desc: {
      type: String
    }
  }
};