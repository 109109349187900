export default {
  name: "PageTabs",
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: "tab"
    },
    selectedId: {
      type: Number
    }
  }
};