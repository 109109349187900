import bus from "@/libs/utils/bus"; //import Camber from "../../assets/collection-store/camber-small-light.svg";

export default {
  name: "TooltipsCard",
  props: {
    desc: {
      type: String
    },
    product: {
      type: Object,
      default: () => ({
        textContent: "-"
      })
    },
    actions: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isToggle: false,
      tipContent: "",
      isActionDisplay: false
    };
  },

  methods: {
    moreAction(e) {
      e.preventDefault();
      bus.emit("item-card-action", this.product.tokenId);
      this.isActionDisplay = !this.isActionDisplay;
    },

    excuteAction(e, actionItem, item) {
      e.preventDefault();

      if (actionItem) {
        actionItem.excuteAction(item);
        this.moreAction(e);
      }
    },

    tipClick() {
      this.isToggle = !this.isToggle;
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.isToggle = false;
      }
    }

  },

  mounted() {
    bus.on("item-card-action", id => {
      if (id !== this.product.tokenId) {
        this.isActionDisplay = false;
      }
    });
  },

  created() {
    window.addEventListener("click", this.close);

    switch (this.desc) {
      case 'base value':
        this.tipContent = "Base Value is determined by portions of the mint price and the royalty fee from the transactions in secondary markets such as OpenSea etc. Base value currency will be exchanged from ETH to USDC at mint time.";
        break;

      case 'reward':
        this.tipContent = "Rewards are earned from the Base Value pool through industry-leading DeFi protocols and will be distributed among the holders on the second Tuesday of each month.";
        break;

      case 'bonus':
        this.tipContent = "Bonuses are rewards from quasi-staking. Bonuses are distributed to the registered Alliance NFT holder daily and can be withdrawn anytime.";
        break;

      default:
        break;
    }
  }

};