import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_master_link_site_nav = _resolveComponent("master-link-site-nav");

  const _component_router_view = _resolveComponent("router-view");

  const _component_master_link_site_footer = _resolveComponent("master-link-site-footer");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_master_link_site_nav, {
    chainId: $props.chainId,
    currentAccount: $props.currentAccount
  }, null, 8, ["chainId", "currentAccount"]), _createVNode(_component_router_view, {
    chainId: $props.chainId
  }, null, 8, ["chainId"]), _createVNode(_component_master_link_site_footer)], 64);
}