export default {
  name: 'MasterLinkLayout',
  props: {
    currentAccount: {
      type: String,
      default: ""
    },
    chainId: {
      type: [Number, String, null],
      default: null
    }
  }
};