import find from "lodash/find";
import { mapState } from "vuex";
import bus from "@/libs/utils/bus";
export default {
  name: "UserSelector",
  props: {
    list: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Number, String, null],
      default: null
    },
    name: {
      type: String,
      default: ""
    },
    avatar: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isOpen: false,
      val: null
    };
  },

  computed: {
    selected() {
      return this.value ? find(this.list, {
        id: this.value
      }) : null;
    },

    ...mapState({
      currentAccount: state => state.wallet.walletAddress,
      walletName: state => state.wallet.walletName
    })
  },
  watch: {
    isOpen(bool) {
      if (bool) bus.emit("list-open", this.name);
    }

  },
  methods: {
    change(item) {
      this.isOpen = false;
      if (item.func) return item.func();

      if (item.route && item.route.name !== this.$route.name) {
        this.$router.push(item.route);
      }
    },

    checkStatus() {
      if (!this.currentAccount) {
        bus.emit("connect-wallet-modal-status", true);
      } else {
        this.isOpen = !this.isOpen;
      }
    }

  },

  mounted() {
    bus.on("list-open", name => {
      if (name !== this.name) this.isOpen = false;
    });
  }

};