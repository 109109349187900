import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-72a51765"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "gallery"
};
const _hoisted_2 = {
  class: "carousel__item"
};
const _hoisted_3 = ["src"];
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import nft1 from '@/assets/master-link/nft-1.png';
import nft2 from '@/assets/master-link/nft-2.png';
import nft3 from '@/assets/master-link/nft-3.png';
import nft4 from '@/assets/master-link/nft-4.png';
import nft5 from '@/assets/master-link/nft-5.png';
import nft6 from '@/assets/master-link/nft-6.png';
export default {
  __name: 'GalleryBlock',

  setup(__props) {
    const list = [nft1, nft2, nft3, nft4, nft5, nft6];
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(Carousel), {
        "items-to-show": 5.5,
        "wrap-around": true,
        autoplay: 1500
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...list, ...list], (nft, index) => {
          return _openBlock(), _createBlock(_unref(Slide), {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
              src: nft,
              alt: "nft",
              width: "240",
              height: "240"
            }, null, 8, _hoisted_3)])]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      }, 8, ["items-to-show"])]);
    };
  }

};