import BN from 'bignumber.js'

BN.config({ RANGE: 100 })

export const briefAddress = (address) => {
    const arr = address ? address.split('') : []
    const len = arr.length
    // const briefMax = len > 40 ? 30 : 40
    const briefMax = 6
    const respell = arr
        .map((w, index) => {
            if (index < briefMax || index > len - 4) return w
            if (index < briefMax + 3) return '.'
            if (index < len - 4) return ''
            return w
        })
        .filter((w) => w)
        .reduce((p, c) => `${p}${c}`, '')
    return respell
}

export const fromWei = (web3, value, to = 'ether') => {
    return web3 && value ? web3.utils.fromWei(value, to) : 0
}
export const toWei = (web3, value, from = 'ether') => {
    return web3 && value ? web3.utils.toWei(value, from) : 0
}
export const toBN = (v) => {
    const value = new BN(`${v}`)
    return v ? value : new BN(0)
}
export const add = (v1, v2) => {
    const value1 = new BN(`${v1}`)
    const value2 = new BN(`${v2}`)
    return v1 || v2 ? value1.plus(value2) : new BN(0)
}
export const sub = (v1, v2) => {
    const value1 = new BN(`${v1}`)
    const value2 = new BN(`${v2}`)
    return v1 || v2 ? value1.minus(value2) : new BN(0)
}
export const mul = (v1, v2) => {
    const value1 = new BN(`${v1}`)
    const value2 = new BN(`${v2}`)
    return v1 || v2 ? value1.multipliedBy(value2) : new BN(0)
}
export const div = (v1, v2) => {
    const value1 = new BN(`${v1}`)
    const value2 = new BN(`${v2}`)
    return v1 && v2 ? value1.dividedBy(value2) : new BN(0)
}
const parse = (num) => {
    return (parseFloat(num) || 0).toLocaleString('zh-TW')
}
export const toAccounting = (num, digit = 0, isNumber) => {
    const numArr =
        Math.abs(parseFloat(num)) < 10 ** -6
            ? (Math.abs(num) + 10 ** -6).toString().replace('1', '0').split('.')
            : parseFloat(num).toString().split('.')
    const accoutingNum = parse(numArr[0])
    const nowLen = numArr[1] ? numArr[1].length : 0
    const digitPadding = Array(digit - nowLen > 0 ? digit - nowLen : 0)
        .fill('0')
        .reduce((p, c) => `${p}${c}`, '')
    // 保持原有小數位，加千位分號
    if (digit === 999 && !isNumber) {
        return `${
            num < 0 && parseInt(numArr[0], 10) === 0 ? '-' : ''
        }${accoutingNum}${numArr[1] ? '.' : ''}${numArr[1] || ''}`
    }
    // 取整數，加千位分號
    if (!digit && !isNumber) {
        return `${
            num < 0 && parseInt(numArr[0], 10) === 0 ? '-' : ''
        }${accoutingNum}`
    }
    // 補0，加千位分號
    if (!nowLen && !isNumber) {
        return `${
            num < 0 && parseInt(numArr[0], 10) === 0 ? '-' : ''
        }${accoutingNum}${digitPadding ? '.' : ''}${digitPadding}`
    }
    // Float
    if (isNumber) {
        return parseFloat(
            `${num < 0 && parseInt(numArr[0], 10) === 0 ? '-' : ''}${parseInt(
                numArr[0],
                10
            )}.${
                digit - nowLen > 0
                    ? numArr[1] || ''
                    : numArr[1]
                    ? numArr[1].slice(0, digit)
                    : ''
            }${digitPadding}`
        )
    }
    return `${
        num < 0 && parseInt(numArr[0], 10) === 0 ? '-' : ''
    }${accoutingNum}.${
        digit - nowLen > 0 ? numArr[1] : numArr[1].slice(0, digit)
    }${digitPadding}`
}

export const rewardABIType = (contractType) => {
    switch (contractType) {
        case 1:
            return 'AutoCompound'
        case 2:
            return 'Bet'
        case 3:
            return 'AutoCompoundCake'
        default:
            return 'AutoCompound'
    }
}
