import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-382eba4b"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "collection-container"
};
const _hoisted_2 = {
  class: "content-block"
};
const _hoisted_3 = {
  class: "content-container"
};
const _hoisted_4 = {
  class: "items-wrapper"
};
const _hoisted_5 = {
  key: 0,
  class: "row loading"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_collection_header = _resolveComponent("collection-header");

  const _component_item_card = _resolveComponent("item-card");

  const _component_router_link = _resolveComponent("router-link");

  const _component_rotate_ring = _resolveComponent("rotate-ring");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_collection_header, {
    minting: true,
    chain: $data.chain,
    collection: $data.collection,
    info: $data.headerInfo
  }, null, 8, ["chain", "collection", "info"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "item-wrapper",
      key: index
    }, [_createVNode(_component_router_link, {
      custom: "",
      to: {
        name: 'CollectionDetail',
        params: {
          id: item.tokenId || item.tokenID,
          chain: $data.chain,
          collection: $data.collection
        }
      }
    }, {
      default: _withCtx(({
        navigate
      }) => [_createVNode(_component_item_card, {
        product: item,
        onClick: navigate
      }, null, 8, ["product", "onClick"])]),
      _: 2
    }, 1032, ["to"])]);
  }), 128)), $data.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_rotate_ring)])) : _createCommentVNode("", true)])])])]);
}