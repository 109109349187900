import AllianceNFT8sian from './alliance-nft-8sian.json';
import AllianceNFTCryptoHodlers from './alliance-nft-crypto-hodlers.json';
import AllianceNFTDemiHuman from './alliance-nft-demi-human.json';
import GenesisNFT8sian from './genesis-8sian.json';
import GenesisNFTCryptoHodlers from './genesis-crypto-hodlers.json';
import GenesisNFTDemiHuman from './genesis-demi-human.json';
export default {
  'alliance-nft-8sian': AllianceNFT8sian,
  'alliance-nft-crypto-hodlers': AllianceNFTCryptoHodlers,
  'alliance-nft-demi-human': AllianceNFTDemiHuman,
  'genesis-demi-human': GenesisNFTDemiHuman,
  'genesis-crypto-hodlers': GenesisNFTCryptoHodlers,
  'genesis-8sian': GenesisNFT8sian,
}