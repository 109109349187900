import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-07ca3d24"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["data-tip-text"];
const _hoisted_2 = ["src", "alt"];
const _hoisted_3 = ["src", "alt"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $props.link ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    onClick: _cache[0] || (_cache[0] = (...args) => $options.excute && $options.excute(...args)),
    class: _normalizeClass(["icon-rounded-wrapper", {
      copied: $data.isCopied
    }]),
    "data-tip-text": $props.tipText
  }, [_createElementVNode("img", {
    class: "normal",
    src: `${$props.images.normal}`,
    alt: $props.type
  }, null, 8, _hoisted_2), _createElementVNode("img", {
    class: "hover",
    src: `${$props.images.hover}`,
    alt: $props.type
  }, null, 8, _hoisted_3)], 10, _hoisted_1)) : _createCommentVNode("", true);
}