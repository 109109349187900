export const mint = ({
  contract,
  payload: { amount, proof, account, value },
  onSuccess,
  onError,
}) => {
  if (proof) {
    return contract.methods
      .mint(amount, proof)
      .send({ from: account, value }, (err, res) => {
        if (err && onError) {
          return onError(err);
        }
        if (onSuccess) return onSuccess(res);
      });
  }
  return contract.methods
    .mint(amount)
    .send({ from: account, value }, (err, res) => {
      if (err && onError) {
        return onError(err);
      }
      if (onSuccess) return onSuccess(res);
    });
};

export const checkWhitelistAmount = ({
  contract,
  payload: { account },
}) => {
  return contract.methods
    .whitelist(account)
    .call();
};

export const nftBalanceOf = ({
  contract,
  payload: { account },
}) => {
  return contract.methods
    .balanceOf(account)
    .call();
};
