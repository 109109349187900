import bus from "@/libs/utils/bus";
import WalletControl from '@/containers/WalletControl.vue';
import DefaultLayout from './DefaultLayout';
import MasterLinkLayout from './MasterLinkLayout';
export default {
  name: 'RouterLayout',
  components: {
    WalletControl,
    DefaultLayout,
    MasterLinkLayout
  },

  data() {
    return {
      chainId: null,
      currentAccount: ''
    };
  },

  computed: {
    layoutName() {
      return this.$route.meta.layout || 'default';
    }

  },

  mounted() {
    bus.on('change-chain-id', id => this.chainId = id);
    bus.on('change-current-account', address => this.currentAccount = address);
  }

};