const state = () => ({
    all: [],
    web3: null,
    walletAddress: '',
    walletName: null, // metamask, walletconnect.
    isTransactionPending: false,
    chainId: null,
});

const getters = {};

const actions = {
    setUserWalletAddress({ commit }, address) {
        commit('SET_USER_WALLET_ADDRESS', address);
    },
    setWeb3({ commit }, payload) {
        commit('SET_WEB3', payload);
    },
    setWalletName({ commit }, payload) {
        commit('SET_WALLET_NAME', payload);
    },
    setTransactionPending({ commit }, bool) {
        commit('SET_IS_TRANSACTION_PENDING', bool);
    },
    setChainId({ commit }, payload) {
        commit('SET_CHAIN_ID', payload);
    },
};

const mutations = {
    ['SET_USER_WALLET_ADDRESS'](state, address) {
        state.walletAddress = address;
    },
    ['SET_WEB3'](state, web3) {
        state.web3 = web3;
    },
    ['SET_WALLET_NAME'](state, name) {
        state.walletName = name;
    },
    ['SET_IS_TRANSACTION_PENDING'](state, bool) {
        state.isTransactionPending = bool;
    },
    ['SET_CHAIN_ID'](state, id) {
        state.chainId = id;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
