import EmailNormal from "@/assets/icon/icon-mail-default.svg";
import EmailHover from "@/assets/icon/icon-mail-primary.svg";
export default {
  name: "CollectionHeader",
  props: {
    info: {
      type: Object,
      default: () => ({
        title: "-",
        profile: "",
        banner: "",
        desc: "Capybara NFT, NFT immersed with intrinsic value mechanism.<br/><br/>For each Capybara NFT, 50% of the minting cost is transferred to the wallet address that garners all base value. The base value collected will be invested in the designated pool of Hotpot Finance to generate a high yield.<br/><br/>There are 1,000 Capybara NFTs in total. Except for the constantly growing intrinsic value, Capybara NFT holders are entitled to the right to mint future mysterious characters, join DAO, invest in unique liquidity pools, partake in BCNT airdrop, etc.",
        // Capybara NFT, NFT immersed with intrinsic value mechanism.<br/><br/>For each Capybara NFT, 50% of the minting cost is transferred to the wallet address that garners all base value. The base value collected will be invested in the designated pool of Hotpot Finance to generate a high yield.<br/><br/>There are 1,000 Capybara NFTs in total. Except for the constantly growing intrinsic value, Capybara NFT holders are entitled to the right to mint future mysterious characters, join DAO, invest in unique liquidity pools, partake in BCNT airdrop, etc.
        items: "-",
        owners: "-",
        links: {
          web: "",
          discord: "",
          twitter: "",
          ig: ""
        }
      })
    },
    minting: {
      type: Boolean,
      default: false
    },
    collection: {
      type: String
    },
    chain: {
      type: String
    }
  },

  data() {
    return {
      isExpand: false
    };
  },

  methods: {
    externalLink(url) {
      window.open(url, "_blank");
    }

  },

  setup() {
    return {
      emailImages: {
        hover: EmailHover,
        normal: EmailNormal
      }
    };
  }

};