import Request from "@/request";
const state = () => ({});

const getters = {};

const actions = {
  getCollections(store) {
    return Request(store, {
      url: `api/v1/investnft/collections`,
      method: "get",
      data: {},
      params: {},
      resource: "bincentive",
    });
  },
  getCollectionList(store, { symbol }) {
    return Request(store, {
      url: `api/v1/investnft/items`,
      method: "get",
      data: {},
      params: { symbol },
      resource: "bincentive",
    });
  },
  getMoralisList(store, { address, chain, format = "decimal" }) {
    return Request(store, {
      url: `nft/${address}/owners`,
      method: "get",
      data: {},
      params: {
        chain,
        format,
      },
    });
  },
  syncItemList(store, { list }) {
    return Request(store, {
      url: `api/v1/investnft/items/refresh`,
      method: "POST",
      data: list,
      params: {},
      resource: "bincentive",
    });
  },
  getItemDetail(store, { address, chain, id, format = "decimal" }) {
    return Request(store, {
      url: `nft/${address}/${id}`,
      method: "get",
      data: {},
      params: {
        chain,
        format,
      },
    });
  },
  getBaseValue(store, { id, address}) {
    const query = `query BaseValue($address: Bytes, $id: Int) {
      tokenInfos(
        first:1,
        orderBy:tokenId,
        orderDirection: desc,
        where:{ nftAddress:$address, tokenId_lte:$id }) {
        id
        depositTime
        amount
        baseValue
        weight
      }
      winnerEntities(
        first:1,
        orderBy:tokenId,
        orderDirection: desc,
        where:{ nft:$address, tokenId:$id }) {
        id
        amount
      }
    }`;
    return Request(store, {
      url: "name/break4ever/invest-nft-gateway-graph",
      method: "post",
      data:{
        query,
        variables: { address, id },
      },
      params: {},
      resource: "hosted-service",
    });
  },
  getBaseValues(store, { address, total = 1, tokenIds = [] }) {
    const query = `query BaseValue($address: Bytes, $total: Int, $tokenIds: [Int!]) {
      tokenInfos(
        first:$total,
        orderBy:tokenId,
        orderDirection: asc,
        where:{ nftAddress:$address${tokenIds.length ? ', tokenId_in:$tokenIds' : ''} }) {
        id
        depositTime
        tokenId
        amount
        baseValue
        weight
      }
      winnerEntities(
        first:$total,
        orderBy:tokenId,
        orderDirection: desc,
        where:{ nft:$address${tokenIds.length ? ', tokenId_in:$tokenIds' : ''} }) {
        id
        tokenId
        amount
      }
    }`;
    return Request(store, {
      url: "name/break4ever/invest-nft-gateway-graph",
      method: "post",
      data:{
        query,
        variables: { address, total, tokenIds },
      },
      params: {},
      resource: "hosted-service",
    });
  },
  getCapybara(store) {
    const query = `{
      nftInfoEntities(
        first:1,
        orderBy:transferTime,
        orderDirection: desc
      ) {
        name
        symbol
        MAX_TOTAL_TOKEN_MINT
        price
        latestMintedId
      }
    }`;
    return Request(store, {
      url: "name/break4ever/capybara-graph",
      method: "post",
      data:{
        query,
        variables: null,
      },
      params: {},
      resource: "hosted-service",
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
