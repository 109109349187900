import bus from "@/libs/utils/bus";
export default {
  name: "CommomModal",
  props: {
    name: {
      type: String,
      default: "common"
    },
    auto: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      bus.emit(`${this.name}-modal-close`);
    }

  }
};