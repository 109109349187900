import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/icon/tip.svg';

const _withScopeId = n => (_pushScopeId("data-v-37b63f29"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "tips-wrapper"
};
const _hoisted_2 = {
  class: "font-caption1 text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
    onClick: _cache[0] || (_cache[0] = $event => $options.tipClick()),
    src: _imports_0,
    alt: ""
  }), _createElementVNode("div", {
    class: _normalizeClass($data.isToggle ? 'wrapper' : 'wrapper hidden')
  }, [_createElementVNode("div", _hoisted_2, _toDisplayString($data.tipContent), 1)], 2)]);
}