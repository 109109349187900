import TitleBlock from "./TitleBlock.vue";
import InfoBlock from "./InfoBlock.vue";
import CorevalueBlock from "./CorevalueBlock.vue";
import BenefitBlock from "./BenefitBlock.vue";
import PartnerBlock from "./PartnerBlock.vue";
import FirstNftBlock from "./FirstNftBlock.vue";
export default {
  components: {
    TitleBlock,
    InfoBlock,
    CorevalueBlock,
    BenefitBlock,
    PartnerBlock,
    FirstNftBlock
  },
  name: "AlliancePage"
};