import featureIcon1 from "@/assets/landingAlliance/feature-icon-1.png";
import featureIcon2 from "@/assets/landingAlliance/feature-icon-2.png";
import featureIcon3 from "@/assets/landingAlliance/feature-icon-3.png";
import featureIcon4 from "@/assets/landingAlliance/feature-icon-4.png";
import featureIcon5 from "@/assets/landingAlliance/feature-icon-5.png";
export default {
  name: "FeaturesBlock",

  data() {
    return {
      featureBadge1: featureIcon1,
      featureBadge2: featureIcon2,
      featureBadge3: featureIcon3,
      featureBadge4: featureIcon4,
      featureBadge5: featureIcon5
    };
  }

};