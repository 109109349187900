export const networkDict = {
    '1': 'Ethereum',
    '5': 'Goerli',
    '56': 'BSC',
};

export const walletNameNetworkDict = {
    'metamask': 'metamaskNetwork',
    'binance': 'binanceNetwork',
    'walletconnect': 'walletconnectNetwork',
};
export const walletTypeNameDict = {
    '0': 'metamask',
    '1': 'binance',
    '2': 'walletconnect',
};