import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-6a486836"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "column"
};
const _hoisted_2 = {
  class: "font-h6 title"
};
const _hoisted_3 = {
  class: "font-body2 desc"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "badge",
    style: _normalizeStyle({
      backgroundImage: `url('${$props.badgeUrl}')`
    })
  }, null, 4), _createElementVNode("h6", _hoisted_2, [_renderSlot(_ctx.$slots, "title", {}, undefined, true)]), _createElementVNode("p", _hoisted_3, [_renderSlot(_ctx.$slots, "desc", {}, undefined, true)])]);
}