import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-123ef995"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "selected-wrapper"
};
const _hoisted_2 = ["src", "alt"];
const _hoisted_3 = {
  class: "font-subtitle1 text_subtitle1 cursor desktop-hidden-tablet"
};
const _hoisted_4 = {
  class: "font-subtitle2 text_subtitle1 cursor tablet-hidden"
};
const _hoisted_5 = {
  key: 0,
  class: "dropdown-wrapper"
};
const _hoisted_6 = {
  class: "options-wrapper"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = ["src", "alt"];
const _hoisted_9 = {
  class: "font-subtitle2 text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "list-wrapper",
    onClick: _cache[0] || (_cache[0] = $event => $data.isOpen = !$data.isOpen)
  }, [_createElementVNode("div", _hoisted_1, [$options.selected ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: $options.selected.img,
    alt: $options.selected.name
  }, null, 8, _hoisted_2)) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_3, _toDisplayString($options.selected ? $options.selected.name : $props.placeholder), 1), _createElementVNode("span", _hoisted_4, _toDisplayString($options.selected ? $options.selected.name : $props.placeholder), 1), _createElementVNode("div", {
    class: _normalizeClass(["arrow", {
      open: $data.isOpen
    }]),
    alt: "arrow"
  }, null, 2)]), $data.isOpen ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, item => {
    return _openBlock(), _createElementBlock("div", {
      class: "option-wrapper cursor",
      key: `${$props.name}-${item.name}`,
      onClick: $event => item.link === undefined ? $options.change($event, item.id) : $options.externalLink(item.link)
    }, [item.img ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      src: item.img,
      alt: item.name
    }, null, 8, _hoisted_8)) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_9, _toDisplayString(item.name), 1)], 8, _hoisted_7);
  }), 128))])])) : _createCommentVNode("", true)]);
}