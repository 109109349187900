export default [
  "0x03644Fd14f7263f85d45Af0a3f9ebB4C99194764",
  "0xf817a1e2b11fb30abad06a828940A49b5BD052D4",
  "0x341b4bE7636000420715cd5f6ac26Ac7BB6F6bD2",
  "0xE77f003C189E5cc6C0f5f7Fc03EE8dD16A1A69cE ",
  "0xF688e460df828109Db2e2d56878b0E8413ad1E94 ",
  "0x8d1DB0c2fcE84a914c7d9Bc291980D79d0D8e8C3 ",
  "0xD6Fe81d2d000618402cE51E891159825228cC114 ",
  "0x3330B6426cFD49362D82b9678fcE2bb9DB8f4E36 ",
  "0xC80CabAA3b56b38085D2207886df6A5C0C85f6D1 ",
  "0xF2344Cc0e8E2AC5b46E7C747D391a57834324919 ",
  "0xAEE10c50E32d1EC1C969b48D50c41Da094f65CC9 ",
  "0x202Dc77A7F433F4B5A8f42BC46B1b5Fbff2635d6 ",
  "0x564eE0b9Acb7Ec7847930551b7C8ce1962255Ab1 ",
  "0x33fC457aF9030bFb6A657784DdAEB0661d33c0A7 ",
  "0x46F5E620C326Ee0275dbDFBdB98320DC0e816c1D ",
  "0x6Ba520246b22907aDB64e312d804E3120e710ef5 ",
  "0xd9bC1a374fA2a0b465982843682da582695C05C1 ",
  "0xf103d92D4a20589B24540861d51Ee25108102038 ",
  "0xf03Ef3E139A037363a9a8e623999A69276449039 ",
  "0x23dfeFf088C16E025cA2E447eF5A6853e2A731Ac ",
  "0x257B504eafF8E04f70D277755AF3B16ED48C098A ",
  "0x80329f59A703c94418167Fd7a2Dd5E11e8DFf733 ",
  "0x66Ef3e4e906FA5987fBe3653Fd08dd99fc96bF3A",
  "0xaa210354040712C37368B8A1160806B7b414f187",
  "0x330816767CF3da3277222B9afC3591Bb0aB494dC",
];
