import PartnershipAlliance from "./PartnershipApplication.vue";
import PartnershipBlock from "./PartnershipBlock.vue";
import FeaturesBlock from "./FeaturesBlock.vue";
import BannerBlock from "./BannerBlock.vue";
import CarouselBlock from "./CarouselBlock.vue";
export default {
  components: {
    BannerBlock,
    FeaturesBlock,
    PartnershipAlliance,
    PartnershipBlock,
    CarouselBlock
  },
  name: "LandingPageAlliance"
};