import { createApp, h } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import router from './router'
import App from './App.vue'
import store from './store'
import "./theme/typography.scss"
import "./theme/layout.scss"
import "./theme/button.scss"
import "./theme/utils.scss"
import 'normalize.css';

const app = createApp({
  render: () => h(App),
})

app.use(ElementPlus)
app.use(store)
app.use(router)
app.mount('#app')
