export default {
  name: "SiteFooter",
  methods: {
    sendMail() {
      window.open("mailto:support@investnft.finance", "_blank");
    },

    externalLink(url) {
      window.open(url, "_blank");
    },

    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }

  }
};