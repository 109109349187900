import bus from "@/libs/utils/bus";
import Layers from "@/assets/icon/icon-layers-default.svg";
import Logout from "@/assets/icon/icon-log-out-default.svg";
export default {
  name: "MasterLinkSiteNav",
  props: {
    currentAccount: {
      type: String,
      default: ""
    },
    chainId: {
      type: [Number, String, null],
      default: null
    }
  },

  data() {
    return {
      isToggle: false,
      isConnectWalletModalDisplay: false,
      avatar: '',
      navs: [{
        link: '#about',
        lable: '關於 DemiHuman2.0'
      }, {
        link: '#benefits',
        lable: 'DemiHuman2.0 六大賦能'
      }, {
        link: '#qna',
        lable: '常見問題'
      }, {
        link: '#tutorial',
        lable: '使用教學'
      }]
    };
  },

  computed: {
    userList() {
      return [{
        img: Layers,
        name: "My NFTs",
        route: {
          name: "UserDashboard"
        }
      }, {
        img: Logout,
        name: "Logout",
        func: this.disconnect
      }];
    }

  },
  methods: {
    disconnect() {
      bus.emit("disconnect-wallet");
      bus.emit("change-current-account", '');
    }

  },
  watch: {
    currentAccount(address) {
      if (address) {
        this.avatar = `https://avatars.dicebear.com/api/identicon/${address}.svg`;
      } else {
        this.avatar = '';
      }
    }

  },

  mounted() {
    bus.on("connect-wallet-modal-close", () => this.isConnectWalletModalDisplay = false);
    bus.on("connect-wallet-modal-status", bool => this.isConnectWalletModalDisplay = bool);

    if (this.address) {
      this.avatar = `https://avatars.dicebear.com/api/identicon/${this.address}.svg`;
    }
  }

};