import axios from "axios";
import { camelizeKeys } from 'humps';


const raw = ({
  url,
  method = "get",
  data,
  params,
  resource = "moralis",
}) => {
  let baseURL;
  const headers = 
  { 
    'Content-Type': 'application/json; charset=UTF-8',
  }
  switch (resource) {
    case "moralis":
      baseURL = "https://deep-index.moralis.io/api/v2/";
      headers['X-API-Key'] = "pqL28NxKVyYFUdQEC1nD2Fxa8Hi0gpVSj15HY7tuTmFlLCB2u365Q9NIBpFuVBlS";
      break;
    case "hosted-service":
      baseURL = "https://api.thegraph.com/subgraphs/";
      break;
    case "bincentive":
      baseURL = process.env.VUE_APP_ENV === 'SIT' ?"https://bi-defi-server-sit.bincentive.com/" : "https://bi-defi-server-prod.bincentive.com/";
      break;
    default:
      break;
  }
  return axios({
    baseURL,
    url,
    method,
    headers,
    data,
    params,
  });
};

const request = (store, options, cbs) => {
  return new Promise((resolve, reject) => {
    raw(options)
      .then((response) => {
        if (cbs && cbs.success) {
          cbs.success(camelizeKeys(response.toJSON()));
        }
        resolve(camelizeKeys(response));
      })
      .catch((error) => {
        console.warn(error.toJSON(), error.response);
        if (cbs && cbs.failure) {
          cbs.failure(camelizeKeys(error));
        }
        reject(camelizeKeys(error));
      });
  });
}

export default request;