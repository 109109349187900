export default {
  name: 'BlockWithImage',
  props: {
    url: {
      type: String,
      required: false
    },
    center: {
      type: Boolean,
      default: false
    },
    imagePosition: {
      type: String,
      required: "left" // left, right

    }
  }
};