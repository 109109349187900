import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-1497bf35"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "tabs-container"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  key: 0,
  class: "icon-wrapper"
};
const _hoisted_4 = ["src", "alt"];
const _hoisted_5 = ["src", "alt"];
const _hoisted_6 = ["src", "alt"];
const _hoisted_7 = {
  class: "text-wrapper"
};
const _hoisted_8 = {
  key: 0,
  class: "font-subtitle1 name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, tab => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["tab-wrapper", {
        selected: $props.selectedId === tab.id
      }]),
      key: `${$props.name}-${tab.id}`,
      onClick: $event => _ctx.$emit('update:selectedId', tab.id)
    }, [tab.imgs ? (_openBlock(), _createElementBlock("div", _hoisted_3, [tab.imgs.primary ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      class: "active",
      src: tab.imgs.primary,
      alt: tab.name
    }, null, 8, _hoisted_4)) : _createCommentVNode("", true), tab.imgs.gray ? (_openBlock(), _createElementBlock("img", {
      key: 1,
      class: "normal",
      src: tab.imgs.gray,
      alt: tab.name
    }, null, 8, _hoisted_5)) : _createCommentVNode("", true), tab.imgs.default ? (_openBlock(), _createElementBlock("img", {
      key: 2,
      class: "hover",
      src: tab.imgs.default,
      alt: tab.name
    }, null, 8, _hoisted_6)) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_7, [tab.name ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(tab.name), 1)) : _createCommentVNode("", true)])], 10, _hoisted_2);
  }), 128))]);
}