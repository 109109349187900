import find from "lodash/find";
import bus from "@/libs/utils/bus";
export default {
  name: "ListSelector",
  props: {
    list: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Number, String, null],
      default: null
    },
    name: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Select network"
    }
  },

  data() {
    return {
      isOpen: false,
      val: null
    };
  },

  computed: {
    selected() {
      return this.value ? find(this.list, {
        id: this.value
      }) : null;
    }

  },
  methods: {
    change(e, id) {
      e.preventDefault();
      this.$emit("change", id);
    },

    externalLink(url) {
      this.isToggle = false;
      window.open(url, "_blank");
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.isOpen = false;
      }
    }

  },
  watch: {
    isOpen(bool) {
      if (bool) bus.emit("list-open", this.name);
    }

  },

  created() {
    window.addEventListener("click", this.close);
  },

  mounted() {
    bus.on("list-open", name => {
      if (name !== this.name) this.isOpen = false;
    });
  }

};