import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-013a5fd6"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "landing-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_title_block = _resolveComponent("title-block");

  const _component_info_block = _resolveComponent("info-block");

  const _component_corevalue_block = _resolveComponent("corevalue-block");

  const _component_benefit_block = _resolveComponent("benefit-block");

  const _component_partner_block = _resolveComponent("partner-block");

  const _component_first_nft_block = _resolveComponent("first-nft-block");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_title_block), _createVNode(_component_info_block), _createVNode(_component_corevalue_block), _createVNode(_component_benefit_block), _createVNode(_component_partner_block), _createVNode(_component_first_nft_block)]);
}