import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-321adb9a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "landing-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_banner_block = _resolveComponent("banner-block");

  const _component_carousel_block = _resolveComponent("carousel-block");

  const _component_features_block = _resolveComponent("features-block");

  const _component_partnership_alliance = _resolveComponent("partnership-alliance");

  const _component_partnership_block = _resolveComponent("partnership-block");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_banner_block), _createVNode(_component_carousel_block), _createVNode(_component_features_block), _createVNode(_component_partnership_alliance), _createVNode(_component_partnership_block)]);
}