import bus from "@/libs/utils/bus";
import ETH from "@/assets/icon/eth.svg";
import BNB from "@/assets/icon/bnb.svg";
import Layers from "@/assets/icon/icon-layers-default.svg";
import Logout from "@/assets/icon/icon-log-out-default.svg";
import File from "@/assets/icon/file.svg";
import Gitbook from "@/assets/icon/gitbook.svg";
export default {
  name: "SiteNav",
  props: {
    currentAccount: {
      type: String,
      default: ""
    },
    chainId: {
      type: [Number, String, null],
      default: null
    }
  },

  data() {
    return {
      isToggle: false,
      isConnectWalletModalDisplay: false,
      avatar: ''
    };
  },

  computed: {
    allianceList() {
      return [{
        img: null,
        name: "Introduction",
        route: {
          name: 'Alliance'
        },
        id: 0
      }, {
        img: null,
        name: "The First Alliance",
        route: {
          name: 'TheFirstAlliance'
        },
        id: 1
      }];
    },

    chainList() {
      return [{
        img: ETH,
        name: "Ethereum",
        id: 1
      }, {
        img: BNB,
        name: "BNB Chain",
        id: 56
      }];
    },

    userList() {
      return [{
        img: Layers,
        name: "My NFTs",
        route: {
          name: "UserDashboard"
        }
      }, {
        img: Logout,
        name: "Logout",
        func: this.disconnect
      }];
    },

    subMenuList() {
      return [{
        img: File,
        name: "Alliance",
        link: "https://s3.ap-northeast-2.amazonaws.com/web-pro.bincentive.com/iNFT/white_paper/InvestNFT+Alliance.pdf"
      }, {
        img: File,
        name: "Deck",
        link: "https://s3.ap-northeast-2.amazonaws.com/web-pro.bincentive.com/iNFT/white_paper/InvestNFT+Deck.pdf"
      }, {
        img: Gitbook,
        name: "Gitbook",
        link: "https://investnft.gitbook.io/investnft/"
      }];
    }

  },
  methods: {
    externalLink(url) {
      this.isToggle = false;
      window.open(url, "_blank");
    },

    goRoute(id) {
      const route = this.allianceList[id].route;
      this.$router.push(route);
      this.isToggle = false;
    },

    mobileClick(r, n) {
      this.$router.push(r);
      n();
      this.isToggle = false;
    },

    checkStatus(navigate) {
      if (!this.currentAccount) {
        this.isConnectWalletModalDisplay = true;
      } else {
        if (this.$route.name !== "UserDashboard") navigate();
      }
    },

    changeNetwork(id) {
      bus.emit("change-network", {
        id
      });
    },

    disconnect() {
      bus.emit("disconnect-wallet");
      bus.emit("change-current-account", '');
    }

  },
  watch: {
    currentAccount(address) {
      if (address) {
        this.avatar = `https://avatars.dicebear.com/api/identicon/${address.toLowerCase()}.svg`;
      } else {
        this.avatar = '';
      }
    }

  },

  mounted() {
    bus.on("connect-wallet-modal-close", () => this.isConnectWalletModalDisplay = false);
    bus.on("connect-wallet-modal-status", bool => this.isConnectWalletModalDisplay = bool);

    if (this.address) {
      this.avatar = `https://avatars.dicebear.com/api/identicon/${this.address.toLowerCase()}.svg`;
    }
  }

};