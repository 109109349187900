import { mapState } from "vuex";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import findIndex from "lodash/findIndex";
import isEmpty from 'lodash/isEmpty';
import bus from "@/libs/utils/bus";
import LayersIconPrimary from "@/assets/icon/icon-layers-primary.svg";
import LayersIconDefault from "@/assets/icon/icon-layers-white.svg";
import LayersIconGray from "@/assets/icon/icon-layers-gray.svg";
import ClaimIconPrimary from "@/assets/icon/icon-layers-claim-primary.svg";
import ClaimIconDefault from "@/assets/icon/icon-layers-claim-white.svg";
import ClaimIconGray from "@/assets/icon/icon-layers-claim-gray.svg";
import QuasiIconPrimary from "@/assets/icon/icon-layers-quasi-staking-primary.svg";
import QuasiIconDefault from "@/assets/icon/icon-layers-quasi-staking-white.svg";
import QuasiIconGray from "@/assets/icon/icon-layers-quasi-staking-gray.svg";
import { briefAddress, div, toAccounting } from "@/libs/utils";
import profile from "@/assets/capybara/avatar_128x128_black.png";
import gatewayAbiV1 from "@/abi/gateway-v1.json";
import gatewayAbiV2 from "@/abi/gateway-v2.json";
import capybaraAbi from "@/abi/capybara.json";
import allianceGlobalAbi from "@/abi/alliance-nft-global.json";
import quasiStakingAbi from "@/abi/quasiStaking.json";
import demiTraderAbi from "@/abi/demi-trader.json";
import ABI from "@/abi";
import { allianceList as allianceListTest, allianceInfo as allianceInfoTest, genesisAddressList as genesisAddressListTest } from "@/libs/const/nft-list-testnet";
import { allianceList, allianceInfo, genesisAddressList } from "@/libs/const/nft-list";
import { nftBalanceOf } from "@/libs/methods";
const ENV = process.env.VUE_APP_ENV;
const ALLIANCE_CHAIN_ID = process.env.VUE_APP_ALLIANCE_CHAIN_ID;
dayjs.extend(duration);
export default {
  name: "UserDashboard",

  data() {
    return {
      tabId: 0,
      address: "",
      avatar: "",
      allianceCollectedList: [],
      capybaraCollectedList: [],
      selfRedeemCollectedList: [],
      claimedList: [],
      collections: {},
      isClaimBoxDisplay: false,
      modalDetail: {},
      isNowSelectedBeApproved: null,
      txStatus: false,
      isExcuting: false,
      baseValueDict: {},
      totalStaking: 0,
      notStaking: 0,
      totalBonus: 0,
      isUnderGraceTime: false,
      genesisBalance: [],
      isRightChainId: null,
      checking: null,
      isStaking: false,
      quasiStakeDisabled: false,
      interval: null
    };
  },

  computed: {
    tabs() {
      return [{
        name: "InvestNFT",
        id: 0,
        value: this.capybaraCollectedList.length + this.allianceCollectedList.length,
        imgs: {
          primary: LayersIconPrimary,
          default: LayersIconDefault,
          gray: LayersIconGray
        }
      }, {
        name: "Quasi-Staking",
        id: 1,
        value: this.allianceCollectedList.length,
        imgs: {
          primary: QuasiIconPrimary,
          default: QuasiIconDefault,
          gray: QuasiIconGray
        }
      }, {
        name: "Claim",
        id: 2,
        value: this.capybaraCollectedList.length + this.allianceCollectedList.length + this.selfRedeemCollectedList.length,
        imgs: {
          primary: ClaimIconPrimary,
          default: ClaimIconDefault,
          gray: ClaimIconGray
        }
      }];
    },

    ...mapState({
      currentAccount: state => state.wallet.walletAddress,
      walletName: state => state.wallet.walletName,
      chainId: state => state.wallet.chainId
    }),

    hasGenesis() {
      const balanceResult = this.genesisBalance.map(balance => balance > 0).reduce((p, c) => p || c, false);
      return balanceResult;
    },

    investNFTReleasedTimeLeft() {
      return dayjs.duration(dayjs().diff(dayjs.unix(1680278400))).asDays();
    },

    totalCollectedList() {
      return [...this.allianceCollectedList, ...this.capybaraCollectedList];
    },

    totalClaimableList() {
      return this.investNFTReleasedTimeLeft > 0 ? [...this.selfRedeemCollectedList, ...this.allianceCollectedList, ...this.capybaraCollectedList] : [...this.selfRedeemCollectedList, ...this.capybaraCollectedList];
    }

  },
  watch: {
    currentAccount(address) {
      if (address) {
        this.getCollections();
        this.getCapybaraList();
        this.getAllAllianceList();
        this.checkGenesisBalance();
        if (this.interval !== null) window.clearInterval(this.interval);
        this.interval = window.setInterval(this.getAllAllianceList, 30000);
      }

      this.address = address.toLowerCase();
      this.avatar = `https://avatars.dicebear.com/api/identicon/${address}.svg`;
    },

    modalDetail(obj) {
      if (Object.prototype.hasOwnProperty.call(obj, "tokenAddress")) {
        this.getAllowance();
      }
    },

    chainId(id) {
      if (id) {
        const result = parseInt(id, 10) === parseInt(ALLIANCE_CHAIN_ID, 10);
        this.isRightChainId = result;

        if (this.currentAccount && result) {
          this.checkGenesisBalance();
        }
      }
    },

    collections(obj) {
      if (!isEmpty(obj)) this.getAllSelfRedeemList();
    }

  },
  methods: {
    getCapybaraList() {
      this.$store.dispatch("token/getMoralisList", {
        address: "0x7dBBf2C8bB713418933d4BdCa7dDC904EC1B956C",
        chain: "bsc"
      }).then(res => {
        this.capybaraCollectedList = [...res.data.result].sort((p, c) => parseInt(c.tokenId, 10) - parseInt(p.tokenId, 10)).filter(i => i.ownerOf.toLowerCase() === this.address.toLowerCase()).map(token => ({ ...token,
          image: JSON.parse(token.metadata)?.image,
          baseValue: {
            token: "BUSD",
            amount: "-"
          },
          bonus: {
            token: "BCNT",
            amount: "0"
          },
          chain: 56,
          reward: {
            token: "BCNT",
            amount: "0"
          },
          project: {
            image: profile,
            name: "Capybara NFT"
          }
        }));
        this.getBaseValues("0x7dBBf2C8bB713418933d4BdCa7dDC904EC1B956C", parseInt(this.capybaraCollectedList[0]?.tokenId || 0, 10));
      });
    },

    async getAllianceList(collection, chain) {
      const {
        address,
        label,
        symbol
      } = collection;
      if (!address) return [];
      let items = null;

      try {
        items = await this.$store.dispatch("token/getCollectionList", {
          symbol
        });
      } catch {
        console.log("data can not found");
      }

      const networkDict = {
        eth: 1,
        bsc: 56,
        goerli: 5
      };
      const itemsList = items?.data?.data.filter(token => token.ownerAddress.toLowerCase() === this.address.toLowerCase()).sort((p, c) => parseInt(c.tokenID, 10) - parseInt(p.tokenID, 10)).map(token => ({ ...token,
        image: token?.metadata ? JSON.parse(token?.metadata)?.image : "https://investnft.mypinata.cloud/ipfs/QmfJFbCj74TvDkLtYZ4Kn6zMeWcuaBkvrGXL244V6TFJH3",
        name: token?.metadata ? JSON.parse(token?.metadata)?.name : token.name || (ENV === "SIT" ? allianceInfoTest : allianceInfo)[symbol].title,
        baseValue: {
          token: "USDC",
          amount: toAccounting(token?.baseValue || 0, 2)
        },
        chain: networkDict[chain] || 1,
        reward: {
          token: "BCNT",
          amount: "0"
        },
        bonus: {
          token: "BCNT",
          amount: "0"
        },
        project: {
          image: (ENV === "SIT" ? allianceInfoTest : allianceInfo)[symbol]?.profile || profile,
          name: (ENV === "SIT" ? allianceInfoTest : allianceInfo)[symbol].title
        },
        symbol,
        label,
        tokenAddress: address,
        quasiStakingReward: toAccounting(token.quasiStakingReward || 0, 2),
        collectionType: this.collections[address.toLowerCase()].type
      }));
      return itemsList;
    },

    async getSelfRedeemList(collection, chain) {
      const {
        address,
        symbol
      } = collection;
      if (!address) return [];
      let items = null;

      try {
        items = await this.$store.dispatch("token/getCollectionList", {
          symbol
        });
      } catch {
        console.log("data can not found");
      }

      const networkDict = {
        eth: 1,
        bsc: 56,
        goerli: 5
      };
      const itemsList = items?.data?.data.filter(token => token.ownerAddress.toLowerCase() === this.address.toLowerCase()).sort((p, c) => parseInt(c.tokenID, 10) - parseInt(p.tokenID, 10)).map(token => ({ ...token,
        tokenId: token.tokenId || token.tokenID,
        image: token?.metadata ? JSON.parse(token?.metadata)?.image : "https://investnft.mypinata.cloud/ipfs/QmfJFbCj74TvDkLtYZ4Kn6zMeWcuaBkvrGXL244V6TFJH3",
        name: token?.metadata ? JSON.parse(token?.metadata)?.name : token.name || (ENV === "SIT" ? allianceInfoTest : allianceInfo)[symbol].title,
        baseValue: {
          token: "ETH",
          amount: toAccounting(token?.baseValue || 0, 2)
        },
        chain: networkDict[chain] || 1,
        reward: {
          token: "BCNT",
          amount: "0"
        },
        bonus: {
          token: "BCNT",
          amount: "0"
        },
        project: {
          image: null,
          name: "Demi Trader"
        },
        symbol,
        tokenAddress: address,
        quasiStakingReward: toAccounting(token.quasiStakingReward || 0, 2),
        collectionType: this.collections[address.toLowerCase()].type
      }));
      return itemsList;
    },

    async getAllAllianceList() {
      return Promise.all((ENV === "SIT" ? allianceListTest : allianceList).map(collection => this.getAllianceList(collection, ENV === "SIT" ? "goerli" : "eth"))).then(res => {
        this.allianceCollectedList = res.reduce((p, c) => [...p, ...c], []);
        this.totalBonus = toAccounting(this.allianceCollectedList.map(token => parseFloat(token.quasiStakingReward || 0)).reduce((p, c) => p + c, 0), 2, true);
        this.totalStaking = this.allianceCollectedList.filter(token => parseInt(token.registerTs, 10)).length;
        this.notStaking = this.allianceCollectedList.length - this.totalStaking;
      });
    },

    async getAllSelfRedeemList() {
      return Promise.all(Object.values(this.collections).filter(collection => collection.type === 3).map(collection => this.getSelfRedeemList(collection, "eth"))).then(res => {
        this.selfRedeemCollectedList = res.reduce((p, c) => [...p, ...c], []);
        this.totalBonus = toAccounting(this.selfRedeemCollectedList.map(token => parseFloat(token.quasiStakingReward || 0)).reduce((p, c) => p + c, 0), 2, true);
      });
    },

    toggleClaimBox(item) {
      if (!this.isClaimBoxDisplay && item) {
        this.modalDetail = item;
      }

      this.isClaimBoxDisplay = !this.isClaimBoxDisplay;
    },

    async getTxStatus(tx) {
      return window.web3.eth.getTransactionReceipt(tx);
    },

    async setTxStatusInterval(tx, callback, callbackSuccess, callBackFailure) {
      const result = await this.getTxStatus(tx);
      this.txStatus = result;

      if (this.txStatus && this.txStatus.status && callbackSuccess) {
        callbackSuccess();
      }

      if (this.txStatus && !this.txStatus.status && callBackFailure) {
        console.warn(this.txStatus);
        callBackFailure();
      }

      if (this.txStatus && callback) callback();

      if (this.txStatus === null) {
        setTimeout(() => {
          this.setTxStatusInterval(tx, callback, callbackSuccess, callBackFailure);
        }, 3000);
      }
    },

    stakingSuccess(refreshList) {
      this.isStaking = false;
      alert("Stake successfully");
      this.syncItemList(refreshList);
    },

    stakingFailure() {
      this.isStaking = false;
      alert("Stake Failed");
    },

    async registerQuasiStakingReward() {
      if (this.quasiStakeDisabled) return;
      if (this.isStaking) return;
      if (this.hasGenesis === false) return alert("You have no genesis NFT");
      const address = process.env.VUE_APP_QUASI_STAKING_ADDRESS;
      const contract = new window.web3.eth.Contract(quasiStakingAbi, address);
      const qualifiedList = this.allianceCollectedList.filter(token => !parseFloat(token.registerTs));
      const addressList = qualifiedList.map(token => token.tokenAddress);
      const idList = qualifiedList.map(token => token.tokenID);
      console.log(addressList, idList);
      if (qualifiedList.length === 0) return;
      this.isExcuting = true;
      this.isStaking = true;
      const refreshList = qualifiedList.map(token => ({
        nftAddress: token.tokenAddress,
        tokenID: parseInt(token.tokenID)
      }));
      await contract.methods.batchRegister(addressList, idList).send({
        from: this.currentAccount
      }, (err, res) => {
        this.isExcuting = false;

        if (err) {
          return console.warn(err);
        }

        return this.setTxStatusInterval(res, this.getAllAllianceList, () => this.stakingSuccess(refreshList), this.stakingFailure);
      });
    },

    async getQuasiStakingReward() {
      if (this.quasiStakeDisabled) return;
      if (this.isUnderGraceTime) return;
      if (this.hasGenesis === false) return alert("You have no genesis NFT");
      const address = process.env.VUE_APP_QUASI_STAKING_ADDRESS;
      const contract = new window.web3.eth.Contract(quasiStakingAbi, address);
      const qualifiedList = this.allianceCollectedList.filter(token => token.quasiStakingReward > 0);
      const addressList = qualifiedList.map(token => token.tokenAddress);
      const idList = qualifiedList.map(token => token.tokenID);
      const refreshList = qualifiedList.map(token => ({
        nftAddress: token.tokenAddress,
        tokenID: parseInt(token.tokenID)
      }));
      console.log(addressList, idList);
      if (qualifiedList.length === 0) return;
      this.isExcuting = true;
      await contract.methods.batchGetRewards(addressList, idList).send({
        from: this.currentAccount
      }, (err, res) => {
        this.isExcuting = false;

        if (err) {
          return console.warn(err);
        }

        this.isUnderGraceTime = true;
        localStorage.setItem("graceTime", dayjs().add(5, "minute").unix());
        this.setTxStatusInterval(res, () => this.syncItemList(refreshList));
      });
    },

    async syncItemList(list) {
      await this.$store.dispatch("token/syncItemList", {
        list
      });
    },

    async getAllowance() {
      const address = this.modalDetail.tokenAddress.toLowerCase();
      const collectionDetail = this.collections[address];
      const interactAbiDict = {
        1: capybaraAbi,
        2: allianceGlobalAbi,
        3: demiTraderAbi
      };
      console.log('Allowance Data', address, this.collections, collectionDetail);
      const contract = new window.web3.eth.Contract(interactAbiDict[collectionDetail.type], address);
      const res = await contract.methods.isApprovedForAll(this.currentAccount, collectionDetail.gatewayAddress.trim()).call();
      console.log("allowance: ", res);
      this.isNowSelectedBeApproved = res;
    },

    async approve() {
      const address = this.modalDetail.tokenAddress.toLowerCase();
      const collectionDetail = this.collections[address];
      const interactAbiDict = {
        1: capybaraAbi,
        2: allianceGlobalAbi,
        3: demiTraderAbi
      };
      const contract = new window.web3.eth.Contract(interactAbiDict[collectionDetail.type], address);
      this.isExcuting = true;
      contract.methods.setApprovalForAll(collectionDetail.gatewayAddress.trim(), true).send({
        from: this.currentAccount
      }, (err, res) => {
        this.isExcuting = false;

        if (err) {
          return console.warn(err);
        }

        return this.setTxStatusInterval(res, this.getAllowance);
      });
    },

    checkAndToggle(item) {
      this.toggleClaimBox(item);
    },

    async claim() {
      const address = this.modalDetail.tokenAddress.toLowerCase();
      const collectionDetail = this.collections[address];
      const id = this.modalDetail.tokenId || this.modalDetail.tokenID;
      const interactAbiDict = {
        1: gatewayAbiV1,
        2: gatewayAbiV2,
        3: demiTraderAbi
      };
      const contract = new window.web3.eth.Contract(interactAbiDict[collectionDetail.type], collectionDetail.gatewayAddress.trim());
      this.isExcuting = true;

      switch (collectionDetail.type) {
        case 1:
          contract.methods.redeem(address, id, 1, false).send({
            from: this.currentAccount
          }, (err, res) => {
            this.isExcuting = false;

            if (err) {
              return console.warn(err);
            }

            return this.setTxStatusInterval(res, () => {
              bus.emit("claim-modal-close");
              this.getCapybaraList();
            });
          });
          break;

        case 2:
          console.log('bababab', address, id, false);
          contract.methods.redeem(address, id, false).send({
            from: this.currentAccount
          }, (err, res) => {
            this.isExcuting = false;

            if (err) {
              return console.warn(err);
            }

            return this.setTxStatusInterval(res, () => {
              bus.emit("claim-modal-close");
              this.getAllAllianceList();
            });
          });
          break;

        case 3:
          contract.methods.redeem(id).send({
            from: this.currentAccount
          }, (err, res) => {
            this.isExcuting = false;

            if (err) {
              return console.warn(err);
            }

            return this.setTxStatusInterval(res, () => {
              bus.emit("claim-modal-close");
              this.getAllSelfRedeemList();
            });
          });
          break;

        default:
          break;
      }
    },

    async getBaseValues(address, total) {
      const res = await this.$store.dispatch("token/getBaseValues", {
        address,
        total
      });
      const data = res.data?.data?.tokenInfos?.length ? res.data.data.tokenInfos.map(token => ({ ...token,
        tokenId: parseInt(token.tokenId, 10)
      })) : null;
      const transformedList = data?.map((token, idx) => {
        const fIndex = findIndex(data, {
          tokenId: token.tokenId
        });
        return { ...token,
          tokenId: fIndex === token.tokenId ? token.tokenId : token.tokenId + (idx - fIndex)
        };
      }) || [];
      const rewardData = res.data.data?.winnerEntities?.length ? res.data.data.winnerEntities.map(token => ({ ...token,
        tokenId: parseInt(token.tokenId, 10)
      })) : null;
      const rewardDict = rewardData?.map(token => ({
        [token.tokenId]: div(token.amount, 1e18).toNumber()
      }))?.reduce((p, c) => ({ ...p,
        ...c
      }), {});
      transformedList.forEach(token => {
        this.baseValueDict[token.tokenId] = {
          baseValue: div(token.baseValue, 1e18).toNumber(),
          weight: div(token.weight, 1e18).toNumber()
        };
      });
      this.capybaraCollectedList = this.capybaraCollectedList.map(token => ({ ...token,
        baseValue: {
          amount: token.ownerOf === "0xc1774E9530714834e1Cd8a7844Bd569542D655b5" ? 0 : this.baseValueDict[token.tokenId].baseValue || 0,
          token: token.baseValue.token
        },
        reward: {
          amount: rewardDict[token.tokenId],
          token: token.reward.token
        },
        bonus: {
          token: "BCNT",
          amount: "0"
        }
      }));
    },

    async getNFTBalanceOf(nft) {
      const Contract = new window.web3.eth.Contract(ABI[nft.label], nft.address);
      let balance = null;

      try {
        balance = await nftBalanceOf({
          contract: Contract,
          payload: {
            account: this.currentAccount
          }
        });
      } catch {
        console.log(null, nft.label);
        return null;
      }

      console.log(balance, nft.label);
      return parseInt(balance, 10);
    },

    async checkGenesisBalance() {
      console.log("check genesis NFT balance", this.isRightChainId);
      if (this.chainId && this.isRightChainId === false) return;
      const genesisList = ENV === "SIT" ? genesisAddressListTest : genesisAddressList;
      this.checking = true;
      Promise.all(genesisList.map(nft => this.getNFTBalanceOf(nft))).then(res => {
        this.genesisBalance = res.map(balance => balance);
        this.checking = false;

        if (this.genesisBalance.filter(balance => balance !== null).length !== this.genesisBalance.length) {
          window.setTimeout(() => this.checkGenesisBalance(), 1200);
        }
      }).catch(err => {
        console.log(err, "checkGenesisBalance");
        this.checking = false;
      });
    },

    getCollections() {
      this.$store.dispatch("token/getCollections").then(res => {
        let result = {};
        res.data.data.forEach(col => {
          result[col.address.toLowerCase()] = col;
        });
        this.collections = result;
      });
    }

  },

  mounted() {
    this.address = this.currentAccount.toLowerCase();

    if (this.address) {
      this.avatar = `https://avatars.dicebear.com/api/identicon/${this.address.toLowerCase()}.svg`;
    }

    bus.on("claim-modal-close", () => {
      this.isClaimBoxDisplay = false;
      this.modalDetail = {};
    });
  },

  created() {
    this.isUnderGraceTime = localStorage.getItem("graceTime") > dayjs().unix();

    if (this.currentAccount) {
      this.getCollections();
      this.getCapybaraList();
      this.getAllAllianceList();
      this.checkGenesisBalance();
      if (this.interval !== null) window.clearInterval(this.interval);
      this.interval = window.setInterval(this.getAllAllianceList, 30000);
    }

    if (this.chainId) {
      const result = parseInt(this.chainId, 10) === parseInt(ALLIANCE_CHAIN_ID, 10);
      this.isRightChainId = result;
    }

    const quasiStakingDate = dayjs("2022/10/07 00:00:00+00:00");

    if (dayjs().diff(quasiStakingDate) > 0 || ENV === "SIT") {
      this.quasiStakeDisabled = false;
    }
  },

  beforeUnmount() {
    if (this.interval !== null) window.clearInterval(this.interval);
  },

  setup() {
    return {
      briefAddress
    };
  }

};