import { getCurrentInstance } from "vue";
import Web3 from "web3";
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import { mapState } from "vuex";
import { networkDict, walletNameNetworkDict, walletTypeNameDict } from "@/libs/const";
import { briefAddress } from "@/libs/utils";
import bus from "@/libs/utils/bus";
export default {
  name: "WalletControl",

  data() {
    return {
      timer: null,
      metamaskNetwork: null,
      walletconnectNetwork: null,
      provider: null,
      retryLeft: {
        metamask: 5
      },
      balance: {
        native: 0
      },
      extensionWallet: {
        web3: null,
        walletName: "",
        provider: null
      },
      defaultWeb3: null,
      firstLoaded: false,
      firstLoadInterval: null,
      logined: false
    };
  },

  computed: { ...mapState({
      currentAccount: state => state.wallet.walletAddress,
      walletName: state => state.wallet.walletName
    })
  },
  watch: {
    "extensionWallet.web3"(instance) {
      if (instance) {
        window.web3 = instance;

        if (!this.logined && JSON.parse(localStorage.getItem("loginAuto"))) {
          this.extensionConnect(0);
          this.logined = true;
        }
      }
    },

    "extensionWallet.walletName"(name) {
      if (name) this.$store.dispatch("wallet/setWalletName", name);
    },

    currentAccount(account) {
      if (account) {
        console.log("now: ", account);
        bus.emit("get-pool-balance", {
          from: "App",
          force: true
        });
        this.firstLoaded = true;
      }
    }

  },
  methods: {
    async extensionConnect(type = 0
    /* 0: metamask */
    ) {
      const walletName = walletTypeNameDict[type];
      const extensionType = walletNameNetworkDict[walletName];
      let provider = null;

      switch (type) {
        case 0:
          provider = window.ethereum;
          break;

        default:
          provider = window.ethereum;
          break;
      }

      this.checkProvider(type);
      this.extensionWallet.walletName = walletName;
      this.$store.dispatch("wallet/setChainId", parseInt(window.ethereum.chainId, 16));
      console.log(walletName, extensionType, this[extensionType]);

      if (["Ethereum", "BSC", "Goerli"].indexOf(this[extensionType]) !== -1 && provider) {
        this.extensionWallet = {
          web3: new Web3(provider),
          walletName,
          provider
        };
        localStorage.setItem("loginAuto", true);
        bus.emit("connect-wallet-modal-status", false);
        provider.request({
          method: "eth_requestAccounts"
        }).then(this.handleAccountsChanged).catch(err => {
          if (err.code === 4001) {
            console.log("Please connect to MetaMask.");
          } else {
            console.error(err);
          }
        });
      }
    },

    async walletConnect() {
      const walletName = walletTypeNameDict[2];
      let provider = null;
      let accounts = [];

      try {
        const walletConnectProvider = await EthereumProvider.init({
          projectId: "e672756f37c4fa0a2024221f8fb4f742",
          chains: [1],
          optionalChains: [56],
          showQrModal: true
        });
        window.walletConnectProvider = walletConnectProvider;
        provider = walletConnectProvider;
        window.walletConnectProvider.on("chainChanged", data => {
          console.log("change to", data);
          bus.emit("change-chain-id", parseInt(data, 16));
          bus.emit('change-network-success', parseInt(data, 16));
          this.$store.dispatch("wallet/setChainId", parseInt(data, 16));
          this.walletconnectNetwork = networkDict[parseInt(data, 10)];
        });
        window.walletConnectProvider.on("accountsChanged", this.handleAccountsChanged);
        accounts = await provider.enable();
      } catch (error) {
        console.log(error);
      }

      this.extensionWallet.walletName = walletName;
      this.extensionWallet.provider = provider;
      this.extensionWallet.web3 = new Web3(provider);
      this.walletconnectNetwork = networkDict[parseInt(provider.chainId, 10)];

      if (accounts && accounts.length) {
        localStorage.setItem("loginAuto", true);
        this.$nextTick(() => this.handleAccountsChanged(accounts));
        bus.emit("connect-wallet-modal-status", false);
      }
    },

    async disconnect() {
      if (this.walletName === "walletConnect") {
        window.walletConnectProvider.disconnect();
      } else {
        await window.ethereum.request({
          method: "eth_requestAccounts",
          params: [{
            eth_accounts: {}
          }]
        });
      }

      localStorage.setItem("loginAuto", false);
      window.clearInterval(this.timer);

      if (this.extensionWallet.provider) {
        this.extensionWallet = {
          web3: null,
          walletName: "",
          provider: null
        };
        this.$store.dispatch("wallet/setUserWalletAddress", "");
        this.$store.dispatch("wallet/setWalletName", null);
        this.$router.replace({
          name: "Landing"
        });
      }
    },

    changeNetwork(id, callback) {
      switch (id) {
        case 1:
          return this.changeToETH(callback);

        case 56:
          return this.changeToBNB(callback);

        default:
          break;
      }
    },

    changeToBNB(callback) {
      if (window.ethereum) {
        window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{
            chainId: "0x38"
          }]
        }).then(() => {
          bus.emit('change-network-success', 56);
          callback();
        }).catch(error => {
          console.log(error.message);

          if (error.code === 4902 || error.code === -32603) {
            window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [{
                chainName: "BSC",
                chainId: "0x38",
                rpcUrls: ["https://bsc-dataseed.binance.org"]
              }]
            });
          }
        });
      }
    },

    changeToETH(callback) {
      if (window.ethereum) {
        window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{
            chainId: "0x1"
          }]
        }).then(() => {
          bus.emit('change-network-success', 1);
          callback();
        }).catch(error => {
          console.log(error.message);

          if (error.code === 4902 || error.code === -32603) {
            window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [{
                chainName: "Ethereum",
                chainId: "0x1",
                rpcUrls: ["https://bsc-dataseed.binance.org"]
              }]
            });
          }
        });
      }
    },

    async checkProvider(type = 0
    /* 0: metamask */
    ) {
      console.log("Check provider and set network name", type);

      switch (type) {
        case 0:
          if (window.ethereum) {
            window.ethereum.on("chainChanged", data => {
              console.log("change to", data);
              bus.emit("change-chain-id", parseInt(data, 16));
              bus.emit('change-network-success', parseInt(data, 16));
              this.$store.dispatch("wallet/setChainId", parseInt(data, 16));
              this.metamaskNetwork = networkDict[parseInt(data, 16)];
            });
            window.ethereum.on("accountsChanged", this.handleAccountsChanged);
            this.metamaskNetwork = networkDict[parseInt(window.ethereum.chainId, 16)];
          } else {
            if (this.retryLeft.metamask > 0) {
              setTimeout(() => {
                this.retryLeft.metamask -= 1;
                this.checkProvider(0);
              }, 3000);
            }
          }

          break;

        default:
          break;
      }
    },

    handleAccountsChanged(accounts) {
      if (this.timer) clearInterval(this.timer);

      if (accounts && (accounts[0] !== this.currentAccount || this.currentAccount)) {
        if (this.walletName && ["Ethereum", "BSC", "Goerli"].indexOf(this[walletNameNetworkDict[this.walletName]] !== -1)) {
          this.$store.dispatch("wallet/setUserWalletAddress", accounts[0]);
          bus.emit("change-current-account", accounts[0]);
          this.getInfo();
          this.timer = window.setInterval(this.getInfo, 15000);
        } else {
          console.log(this[walletNameNetworkDict[this.walletName]]);

          if (this.walletName === "walletconnect") {
            window.walletConnectProvider.disconnect();
          }
        }
      }
    },

    toggleScrollLocked(bool) {
      const appElm = document.querySelector("#app");
      appElm.classList.toggle("locked", bool);
    },

    getInfo() {
      const b = this.extensionWallet.web3.eth.getBalance(this.currentAccount);
      b.then(bal => {
        this.balance.native = this.extensionWallet.web3.utils.fromWei(bal, "ether");
      });
    },

    setFirstLoad() {
      if (this.currentAccount) {
        this.firstLoaded = true;
        window.clearInterval(this.firstLoadInterval);
      }
    }

  },

  mounted() {
    const loginAuto = JSON.parse(localStorage.getItem("loginAuto"));

    if (window.ethereum) {
      this.extensionWallet.web3 = new Web3(window.ethereum);
      this.$nextTick(() => {
        if (window.ethereum.chainId) {
          bus.emit("change-chain-id", parseInt(window.ethereum.chainId, 16));
          this.$store.dispatch("wallet/setChainId", parseInt(window.ethereum.chainId, 16));
        }

        bus.on("change-network", ({
          id,
          callback
        }) => this.changeNetwork(id, callback));
      });

      if (!this.logined && loginAuto) {
        this.logined = true;
        return this.extensionConnect(0);
      }

      this.checkProvider(0);
    } else {
      setTimeout(() => {
        this.checkProvider(0);

        if (!this.logined && loginAuto) {
          this.extensionConnect(0);
          this.logined = true;
        }
      }, 1000);
    }

    this.firstLoadInterval = window.setInterval(this.setFirstLoad, 1000);
  },

  setup() {
    const {
      ctx
    } = getCurrentInstance();
    bus.on("disconnect-wallet", () => ctx.disconnect());
    bus.on("locked-app-scroll", ctx.toggleScrollLocked);
    bus.on("metamask", bool => {
      if (bool) ctx.extensionConnect(0);
    });
    bus.on("walletConnect", bool => {
      if (bool) {
        ctx.walletConnect();
        bus.emit("connect-wallet-modal-close");
      }
    });
    return {
      briefAddress
    };
  }

};